import React, {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import api from "../../../api/api"
import {
  customDeleteConsultationConfig,
  deleteConsultationConfig,
  getConsultationConfig
} from "../../../api/routes"
import {internistSpecializationId} from "../../../app.config"
import useIsB2BClinic from "../../../hooks/useIsB2BClinic"
import {analytics, LogEventType} from "../../../services/analytics"
import store from "../../../store"
import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"
import AppDialog from "../../common/appDialog/AppDialog.component"
import ButtonLoader from "../../common/buttonLoader/ButtonLoader.component"
import GlobalFormErrorMessage
  from "../../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import {ConsultationModel} from "../Consultation.types"

interface CancelConsultationPopupProps {
  open: boolean;
  consultationId: string;
  onClose(): void;
  onSuccess(): void;
  goToTermSelection?(): void;
  customLabel?: string;
  customTitle?: string;
  customCancelation?: boolean;
  closeOnSuccess?: boolean;
  dialogCustomParams?: dialogCustomParamsType | null;
  containerCustomClass?: string | null;
}

type dialogCustomParamsType = {
  label?: string;
  title?: string;
  cancelLabel?: string;
  confirmLabel?: string;
  hideButtonIcon?: boolean;
}

const CancelConsultationPopup: FC<CancelConsultationPopupProps> = (
  {
    open,
    consultationId,
    onClose,
    onSuccess,
    dialogCustomParams,
    customCancelation,
    goToTermSelection,
    closeOnSuccess = true,
    containerCustomClass
  }) => {
  const {t} = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>("")
  const [cancelError, setCancelError] = useState<boolean>(false)
  const [consultationData, setConsultationData] = useState<ConsultationModel>()
  const {IsMainClinic} = useIsB2BClinic()
  useEffect(() => {
    if (!consultationData) {
      getConsultationData()
    }
  }, [consultationId])

  const handleClose = () => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_CONSULTATION_CANCEL_ALERT_CANCEL_BUTTON_CLICK)
    setError("")
    setCancelError(false)
    onClose()
  }

  const handleGoToTermSelection = () => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_CONSULTATION_CANCEL_ALERT_CANCEL_BUTTON_CLICK)
    goToTermSelection && goToTermSelection()
  }

  const onConsultationCancelConfirm = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    (event.target as HTMLButtonElement).blur()
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_CONSULTATION_CANCEL_ALERT_CONFIRM_BUTTON_CLICK)

    setIsLoading(true)
    try {
      customCancelation
        ? await api.request({...customDeleteConsultationConfig(consultationId)})
        : await api.request({...deleteConsultationConfig(consultationId)})
      onSuccess()
      closeOnSuccess && onClose()
    }
    catch (e) {
      setCancelError(true)
      if (e.response?.data && e.response.status !== 500) {
        setError(e.response.data)
      }
    }

    setIsLoading(false)
  }
  const getCancellationPolicyMessage = () => {
    const isFreeConsultation = consultationData?.cost==="0" || consultationData?.cost==="-1"
    const isPayed = consultationData?.is_payed === true
    if (!isPayed || isFreeConsultation || !IsMainClinic || cancelError) {
      return null
    }
    const user = store.getState().user.userData
    if (user?.is_active_telemedi_go) {
      return null
    }

    const time = consultationData?.specialization.id === internistSpecializationId
      ? 1
      : 1.5
    const timeUnitKey = time == 1 ? "one" : "few"
    const timeUnit = t(`consultation:cancelConsultationPopup:cancellationPolicy:timeUnit:${timeUnitKey}`)
    return t("consultation:cancelConsultationPopup:cancellationPolicy:message", {
      time: time,
      timeUnit: timeUnit
    })
  }
  const getConsultationData = async () => {
    try {
      const {data} = await api.request<ConsultationModel>({
        ...getConsultationConfig(consultationId),
      })
      setConsultationData(data)
    } catch (e) {
      if (api.isCancel(e)) return
      console.error(e)
    }
  }

  const dialogParams = {
    title: dialogCustomParams?.title || t("consultation:cancelConsultationPopup:title"),
    actionButtonText: cancelError ? "" : (dialogCustomParams?.confirmLabel || t("consultation:cancelConsultationPopup:confirm")),
    actionButtonProps: cancelError
      ? {}
      : {
        onClick: onConsultationCancelConfirm,
        disabled: isLoading,
        startIcon: isLoading
          ? <ButtonLoader position="prefix"/>
          : (!dialogCustomParams?.hideButtonIcon ?
            <IconByIntegrationType {...{ "data-testid": "trash-icon" }}
              iconName={"icon-trash"}
              returnTypography={true}
            />
            : null
          )
      },
    closeButtonText: cancelError ? "" : (dialogCustomParams?.cancelLabel || t("consultation:cancelConsultationPopup:cancel")),
    description: cancelError
      ? t("consultation:cancelConsultationPopup:cancelError")
      : dialogCustomParams?.label || t("consultation:cancelConsultationPopup:description"),
    cancellationPolicyMessage: getCancellationPolicyMessage(),
    closeButtonProps: {
      disabled: isLoading,
      onClick: customCancelation ? handleGoToTermSelection : handleClose
    }
  }

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title={dialogParams.title || ""}
      actionButtonText={dialogParams.actionButtonText}
      actionButtonProps={dialogParams.actionButtonProps}
      closeButtonText={dialogParams.closeButtonText}
      closeButtonProps={dialogParams.closeButtonProps}
      classes={{
        paper: containerCustomClass ?? undefined
      }}
    >
      <Box mb={1}>
        <Typography>
          {dialogParams.description}
          {dialogParams.cancellationPolicyMessage && (
            <Typography>
              <br/> {dialogParams.cancellationPolicyMessage}
            </Typography>
          )}
        </Typography>
      </Box>

      { error && (
        <GlobalFormErrorMessage message={error}/>
      )}
    </AppDialog>
  )
}

export default CancelConsultationPopup
