import React, { FC, useEffect, useState } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box, useMediaQuery, useTheme } from "@material-ui/core"

import api from "../../api/api"
import { getEmployerDataByNip } from "../../api/routes"
import addIcon from "../../assets/svg/plusCircle.svg"
import trashIcon from "../../assets/svg/trash.svg"
import ImageByIntegrationType from "../imageByIntegrationType/ImageByIntegrationType"
import TextFieldController from "../commonFormItems/textFieldController/TextFieldController.component"
import { GusApiCompanyDataResponse } from "../../store/gus/gus.types"
import { useSickLeaveSectionStyles } from "./SickLeaveSurvey.styles"

interface EmployerFieldsProps {
  limit: number;
  name?: string;
}

interface CompanyFieldsState {
  disabled: boolean;
}

const EmployerFieldsComponent: FC<EmployerFieldsProps> = ({limit, name = "employer"}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"))
  const { fields, remove, append } = useFieldArray({
    name
  })
  const classes = useSickLeaveSectionStyles()
  const [nipChangeTimeout, setNipChangeTimeout] = useState<NodeJS.Timeout>()
  const {
    setValue,
    setError,
    formState: { errors }
  } = useFormContext()
  const [companyFieldsStates, setCompanyFieldsStates] = useState<CompanyFieldsState[]>([])
  //add first field on mount
  useEffect(() => {
    if(fields?.length!==0){
      return
    }
    append({employerField: ""})
  }, [])

  const setDisableStatus = (companyFieldIndex: number, status: boolean) => {
    setCompanyFieldsStates((prevStates) => {
      const newStates = [...prevStates]
      newStates[companyFieldIndex] = {disabled: status}
      return newStates
    })
  }

  const clearEmployerData = (index: number) => {
    setValue(`${name}.${index}.addressItem`, "")
    setValue(`${name}.${index}.nameItem`, "")
  }

  const fillInCompanyData = (value: string, index: number) => {
    const isValid = !errors?.[name]?.[index]?.nipItem

    if (!isValid) {
      setValue(`${name}.${index}.addressItem`, "")
      setValue(`${name}.${index}.nameItem`, "")
      return
    }

    setDisableStatus(index, true)
    setValue(`${name}.${index}.addressItem`, t("consultation:sickLeave:nipSearchFieldInfo"))
    setValue(`${name}.${index}.nameItem`, t("consultation:sickLeave:nipSearchFieldInfo"))

    getEmployerData(value).then(
      (data: GusApiCompanyDataResponse) => {
        setValue(`${name}.${index}.addressItem`, data.address)
        setValue(`${name}.${index}.nameItem`, data.name)

        setDisableStatus(index, true)
      }
    ).catch(() => {
      setError(`${name}.${index}.nipItem`, {
        type: "incorrect-nip",
        message: t("consultation:sickLeave:cantGetNipData"),
      })

      clearEmployerData(index)
      setDisableStatus(index, false)
    })
  }

  const handleNipItemChange = (index: number, value: string) => {
    if (nipChangeTimeout) {
      clearTimeout(nipChangeTimeout)
    }

    setNipChangeTimeout(setTimeout(() => {
      fillInCompanyData(value, index)
    }, 2000))
  }

  const getEmployerData = async (nip: string) => {
    const {data: employerData} = await api.request({
      ...getEmployerDataByNip(nip)
    })
    return employerData
  }

  const EmployerFieldsItems = fields.map((item, index) => {
    const indexIncrement = index + 1
    const showAddIcon = (indexIncrement < limit) && indexIncrement === fields.length
    const showTrashIcon = fields.length > 1
    const trashIconContent = (
      <span onClick={() => remove(index)}>
        <ImageByIntegrationType
          imageSrc={trashIcon}
          alt="trashIcon"
          className={classes.trashIcon}
          imagePath={"trash.svg"}
        />
      </span>
    )
    const addIconContent = (
      <span onClick={() => append({employerField: ""})}>
        <ImageByIntegrationType
          imageSrc={addIcon}
          alt="addIcon"
          className={classes.addIcon}
          imagePath={"plusCircle.svg"}
        />
        <span className={classes.addNewItemText}>{t("consultation:addNewNip")}</span>
      </span>
    )

    return (
      <Box
        display="flex"
        flexDirection="column"
        key={item.id}
        id="employer" //needed for yup validation
      >
        <Box
          display="flex"
          flexDirection={isSmUp ? "row" : "column"}
        >
          <TextFieldController
            name={`${name}.${index}.nipItem`}
            label={`${t("consultation:sickLeaveNip")} ${index > 0 ? index + 1 : ""}*`}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: null,
              required: true
            }}
            className={classes.employerField}
            onInput={(e) => handleNipItemChange(index, (e.target as HTMLInputElement).value)}
          />
          {
            showAddIcon && (
              <Box
                display="flex"
              >
                {
                  showTrashIcon && trashIconContent
                }
                {addIconContent}
              </Box>
            )
          }
          {
            (showTrashIcon && !showAddIcon) && trashIconContent
          }
        </Box>
        <Box
          display="flex"
          flexDirection={isSmUp ? "row" : "column"}
        >
          <TextFieldController
            name={`${name}.${index}.nameItem`}
            label="Nazwa Firmy*"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: null,
              required: true
            }}
            disabled={companyFieldsStates[index]?.disabled ?? true}
            className={classes.employerField}
          />
          <TextFieldController
            name={`${name}.${index}.addressItem`}
            label="Adres Firmy*"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: null,
              required: true
            }}
            disabled={companyFieldsStates[index]?.disabled ?? true}
            className={classes.employerNotFirst}
          />
        </Box>
      </Box>
    )
  })

  return (
    <Box>
      {EmployerFieldsItems}
    </Box>
  )
}

export default EmployerFieldsComponent
