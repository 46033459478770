import store from "../store"
import { ClinicType } from "../components/settingsPanel/clinicSelect/Clinic.types"

interface useIsB2ClinicReturnType {
  B2BClinic: boolean;
  IsMainClinic: boolean;
}

const useIsB2BClinic = (): useIsB2ClinicReturnType => {
  let B2BClinic = false
  let IsMainClinic = false

  if (store) {
    B2BClinic = store.getState()?.clinic?.clinicSettings?.frontendSettings?.reactAppClinicType === ClinicType.B2B
    IsMainClinic = store.getState()?.clinic?.clinicSettings?.frontendSettings?.reactAppClinicType === ClinicType.MAIN
  }

  return {
    B2BClinic,
    IsMainClinic
  }
}

export default useIsB2BClinic
