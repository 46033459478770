import React, { FC, useEffect,useState } from "react"
import {Container} from "@material-ui/core"

import {useRemoveIsFirstSessionInvoked} from "../../hooks/useRemoveIsFirstSessionInvoked"
import { analytics, LogEventType } from "../../services/analytics"
import PasswordChangesSucessBox from "../../components/forgotPassword/PasswordChangesSucessBox.component"
import SendNewPasswordForm from "../../components/forgotPassword/SendNewPasswordForm.component"
import SendSmsCodeForm from "../../components/forgotPassword/SendSmsCodeForm.component"
import SendUsernameForm from "../../components/forgotPassword/SendUsernameForm.component"
import {ResetPasswordSession} from "./ForgotPasswordPage.types"
import { useForgotPasswordPageStyles } from "./ForgotPasswordPage.styles"

const ForgotPasswordPage: FC = () => {
  useRemoveIsFirstSessionInvoked()

  const classes = useForgotPasswordPageStyles()

  const [userId, setUserId] = useState<string>()
  const [session, setSession] = useState<ResetPasswordSession>()
  const [successPasswordChanged, setSuccessPasswordChanged] = useState<boolean>(false)
  const params = new URLSearchParams(window.location.search)
  const userIdFromUrl = Object?.fromEntries?.(params)?.userId
  const accountIdFromUrl = Object?.fromEntries?.(params)?.accountId

  useEffect(() => {
    analytics.sendEventWithDefaultParams(LogEventType.FORGOT_PASSWORD_PAGE_LOADED)
  }, [])

  return (
    <Container
      maxWidth={"sm"}
      className={classes.root}
    >
      { (!userId && !session && !userIdFromUrl && !accountIdFromUrl) &&
        <SendUsernameForm
          setUserId={setUserId}
        />
      }

      { ((userId || userIdFromUrl || accountIdFromUrl) && !session) &&
        <SendSmsCodeForm
          userId={userIdFromUrl || userId || accountIdFromUrl || ""}
          setSession={setSession}
        />
      }

      { (session && !successPasswordChanged) &&
        <SendNewPasswordForm
          session={session}
          setSuccessPasswordChanged={setSuccessPasswordChanged}
        />
      }

      { ((userId || userIdFromUrl || accountIdFromUrl) && session && successPasswordChanged) &&
        <PasswordChangesSucessBox/>
      }
    </Container>
  )
}

export default ForgotPasswordPage
