import React, { FC, useMemo,useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { Box, Button, Divider, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"
import { parseISO } from "date-fns"

import clinicImg from "../../../assets/images/clinic.png"
import consultationDateImg from "../../../assets/images/consultationDate.png"
import doctorImg from "../../../assets/images/doctor.png"
import StationaryVisitImage from "../../../assets/images/stationaryVisit.png"
import cancelationImg from "../../../assets/images/visit-cancelled-stationary.png"
import { useAppSelector } from "../../../hooks/storeHooks"
import useGoToConsultationPage from "../../../hooks/useGoToConsultationPage"
import { useQueryParam } from "../../../hooks/useSearchParams"
import store from "../../../store"
import { selectClinicSettings } from "../../../store/clinic/clinicSettings.selectors"
import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import useImageByIntegrationType from "../../imageByIntegrationType/useImageByIntegrationType"
import DoctorImageAvatar from "../../common/imageAvatar/doctorImageAvatar/DoctorImageAvatar.component"
import LangFlag from "../../common/langFlag/LangFlag.component"
import PageSection from "../../common/pageSection/PageSection.component"
import TextLabel from "../../common/textLabel/TextLabel.component"
import CancelConsultationPopup from "../../consultation/cancelConsultationPopup/CancelConsultationPopup.component"
import { redirectToPaymentUrl } from "../../../utils/redirectToPaymentUrl"
import { getLangFlagImgTitle } from "../../common/langFlag/LangFlag.utils"
import { getStationaryVisitAddress } from "../../consultation/Consultation.utils"
import { getDoctorAvatarPseudoRandomBoolean } from "../../doctor/Doctor.utils"
import { RoutePath } from "../../../routes/Routes.types"
import { ConsultationWidgetVisitDatePicker } from "../../../store/makeConsultationWidget/makeConsultationWidget.types"
import { ConsultationModel } from "../../consultation/Consultation.types"
import { useCommonStyles } from "../../services/comingServiceSections/common/Common.styles"
import { useInformationPanelStyles } from "../InformationPanel.styles"
import { useStationaryDetailsPanelStyles } from "./StationaryDetailsPanel.styles"

export const CLINIC_ADDRESS = "ul. Biały Kamień 2, Warszawa"
export const CLINIC_NAME = ""
// it is only one stationary clinic with this address

interface StationaryDetailsPanelProps {
  consultationData: ConsultationModel;
}

const StationaryDetailsPanel: FC<StationaryDetailsPanelProps> = ({ consultationData }) => {
  const { t , i18n} = useTranslation()
  const { push } = useHistory()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const isPayment = useQueryParam("payment")
  const isInterestedInPoz = consultationData.is_interested_in_poz
  const consultationLanguage = consultationData.language
  const isDoctorAssigned = !!consultationData.doctorId
  const isStationaryVisitPatientCheckboxEnabled = store.getState().clinic?.clinicSettings?.stationaryVisitPatient
  const informationPanelClasses = useInformationPanelStyles()
  const classes = useStationaryDetailsPanelStyles()
  const { imageSrcState } = useImageByIntegrationType("stationaryVisit.png",StationaryVisitImage)
  const commonClasses = useCommonStyles()
  const consultationStartISODate = consultationData.visit_at
  const doctorAvatarPseudorandomBoolean = getDoctorAvatarPseudoRandomBoolean(consultationData.specialization.name)
  const [isCancelConsultationModalOpen, setIsCancelConsultationModalOpen] = useState<boolean>(false)
  const { frontendSettings: { modules, contactPhoneNumber } } = useAppSelector(selectClinicSettings)
  const isS7Enabled = modules.includes("s7health")
    && consultationData.canCancelStationaryVisit
  const avatarImg = !isDoctorAssigned
    ? imageSrcState
    : consultationData.doctor.avatar || undefined

  const parseConsultationStartDate = (dateTranslationKey: string) => {
    if (!consultationStartISODate) return null
    const consultationStartParsedDate = parseISO(consultationStartISODate)
    return t(`dates:${dateTranslationKey}`, { date: consultationStartParsedDate })
  }

  const consultationStartDate = useMemo((): string|null => {
    return parseConsultationStartDate("consultationDayWithFullYearDate")
  }, [])

  const consultationStartHourDate = useMemo((): string|null => {
    return parseConsultationStartDate("time")
  }, [])

  const goToStationaryPozConsultationPage = useGoToConsultationPage({
    visitDatePicker: ConsultationWidgetVisitDatePicker.STATIONARY_VISIT_DATA_PICKER
  })

  const goToHomepage = () => {
    push(`/${i18n.language}`)
  }

  const goToMakeStationaryVisitPage = () => {
    push(`/${i18n.language}${RoutePath.MAKE_STATIONARY_CONSULTATION}`)
  }

  return (
    <PageSection>
      <Box className={clsx(informationPanelClasses.pageSection, classes.pageSection)}>
        {/* doctor section */}
        <Box flex={1}>
          <Typography
            variant={isMdUp ? "h2" : "h3"}
            className={classes.pageTitle}
            component="h2"
          >
            { t("consultation:stationaryDetailsView:title") }
          </Typography>

          <Box className={classes.section}>
            <Box>
              <DoctorImageAvatar
                pseudorandomBoolean={doctorAvatarPseudorandomBoolean}
                className={clsx(
                  classes.iconImage,
                  !isDoctorAssigned ? classes.hospitalImage : null,
                  isDoctorAssigned && !consultationData?.doctor?.avatar 
                    ? classes.iconAvatar 
                    : null
                )}
                src={avatarImg}
              />
            </Box>
            <Box>
              <Typography className={clsx(classes.sectionTitle, classes.doctorSectionTitle)}>
              </Typography>
              {
                isDoctorAssigned ? (
                  <Typography className={classes.sectionTitle}>
                    {consultationData.doctorNameWithDegree}
                  </Typography>
                ) : (
                  <Box mb={1}>
                    <TextLabel text={consultationData.specialization.name}/>
                  </Box>
                )
              }
              {
                consultationLanguage && (
                  <LangFlag
                    language={consultationLanguage}
                    title={getLangFlagImgTitle(consultationLanguage)}
                    label={getLangFlagImgTitle(consultationLanguage)}
                    className={commonClasses.langImg}
                  />
                )
              }
            </Box>
          </Box>
        </Box>
        {/* e/o doctor section */}

        <Box className={classes.infoSectionsWrapper}>
          {/* when section */}
          {
            consultationStartDate && (
              <Box className={classes.section}>
                <Box className={classes.imageWrapper}>
                  <ImageByIntegrationType
                    imageSrc={consultationDateImg}
                    alt="consultationDateImg"
                    className={classes.image}
                    imagePath={"consultationDate.png"}
                  />
                </Box>

                <Box>
                  <Typography className={classes.sectionTitle}>
                    {t("consultation:stationaryDetailsView:when")}
                  </Typography>
                  <Typography component="time" className={classes.whenSection}>
                    <span className={classes.text}>{consultationStartDate}</span>
                    <span className={classes.text}>
                      {t("consultation:stationaryDetailsView:hour")}: {consultationStartHourDate}
                    </span>
                  </Typography>
                </Box>
              </Box>
            )
          }
          {/* e/o when section */}

          {
            consultationData.stationaryVisitAddress && (
              <Box className={classes.section}>
                <Box className={classes.imageWrapper}>
                  <ImageByIntegrationType
                    imageSrc={clinicImg}
                    alt="clinicImg"
                    className={classes.image}
                    imagePath={"clinic.png"}
                  />
                </Box>

                <Box>
                  <Typography className={classes.sectionTitle}>
                    {t("consultation:stationaryDetailsView:where")}
                  </Typography>
                  <Typography>
                    <span className={classes.text}>
                      {getStationaryVisitAddress(consultationData.stationaryVisitAddress)}
                    </span>
                  </Typography>
                </Box>
              </Box>
            )
          }

          <Box className={classes.section}>
            <Box className={classes.imageWrapper}>
              <ImageByIntegrationType
                imageSrc={cancelationImg}
                alt="CancellationImg"
                className={classes.image}
                imagePath={"visit-cancelled-stationary.png"}
              />
            </Box>

            <Box>
              <Typography className={classes.sectionTitle}>
                { t("consultation:stationaryDetailsView:cancelationTitle") }
              </Typography>
              <Typography>
                <span className={classes.text}>
                  { isS7Enabled 
                    ? t("consultation:stationaryDetailsView:callToUsS7") 
                    : t("consultation:stationaryDetailsView:callToUs") 
                  }
                </span>
                <span className={classes.text}>
                  {contactPhoneNumber ? contactPhoneNumber : t("serviceDeskPhone")}
                </span>
              </Typography>
            </Box>
          </Box>

          {/* consultation type section */}
          <Box className={classes.section}>
            <Box className={classes.imageWrapper}>
              <ImageByIntegrationType
                imageSrc={doctorImg}
                alt="doctorImg"
                className={classes.image}
                imagePath={"doctor.png"}
              />
            </Box>

            <Box>
              <Typography className={classes.sectionTitle}>
                { t("consultation:stationaryDetailsView:consultationType") }
              </Typography>
              <Typography>
                <span className={classes.text}>
                  { t("consultation:coming:onSiteConsultation") }
                </span>
              </Typography>
            </Box>
          </Box>
          {/* e/o consultation type section */}
        </Box>

        <Divider color={theme.palette.grey["100"]}/>

        <Box className={classes.buttonsWrapper}>
          <Button
            variant="contained"
            color={"primary"}
            className={classes.button}
            onClick={goToHomepage}
          >
            { t("consultation:stationaryDetailsView:goToHomepage") }
          </Button>
          {
            isPayment && consultationData?.is_payed === false && (
              <Button
                variant="contained"
                color={"secondary"}
                className={classes.button}
                onClick={() => redirectToPaymentUrl(consultationData.id, i18n.language, consultationData.paymentLink)}
              >
                {t("consultation:pay")}
              </Button>
            )
          }
          {
            isStationaryVisitPatientCheckboxEnabled && (
              <Button
                variant="outlined"
                color={"secondary"}
                className={classes.button}
                onClick={() => isInterestedInPoz ? goToStationaryPozConsultationPage.goToMakeConsultationPage() : goToMakeStationaryVisitPage()}
              >
                {t("consultation:stationaryDetailsView:makeAnotherConsultation")}
              </Button>
            )
          }
        </Box>
      </Box>

      <CancelConsultationPopup
        open={isCancelConsultationModalOpen}
        consultationId={consultationData.id}
        onClose={() => {setIsCancelConsultationModalOpen(false)}}
        onSuccess={goToHomepage}
      />
    </PageSection>
  )
}

export default StationaryDetailsPanel
