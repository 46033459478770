import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import {useHistory} from "react-router"
import { Box, useMediaQuery, useTheme } from "@material-ui/core"
import { CancelTokenSource } from "axios"
import i18next from "i18next"

import api, { ApiInstance } from "../api/api"
import {wasEpuapReSignFlowTriedOnLogin, wasSwitchAccountPageShownOnLogin} from "../app.config"
import { useAppDispatch, useAppSelector } from "../hooks/storeHooks"
import useIsB2BClinic from "../hooks/useIsB2BClinic"
import { useQueryParam } from "../hooks/useSearchParams"
import { analytics, LogEventType } from "../services/analytics"
import { selectClinicSettings } from "../store/clinic/clinicSettings.selectors"
import {
  selectCancelledConsultationsVisible,
  selectComingConsultations,
  selectLatestConsultations,
} from "../store/consultations/consultations.selectors"
import {
  clearComingConsultations,
  clearLatestConsultations,
  setCancelledVisible,
  setComingConsultations,
  setComingConsultationsTotal,
  setLatestConsultations,
  setLatestConsultationsTotal,
} from "../store/consultations/consultations.slice"
import { selectComingExamsItems, selectComingExamsLoading } from "../store/exams/exams.selectors"
import { clearComingExams, getComingExams } from "../store/exams/exams.slice"
import {selectMagicLinkExpired} from "../store/logIn/logIn.selectors"
import {setMagicLinkExpired} from "../store/logIn/logIn.slice"
import { selectUserId } from "../store/session/session.selectors"
import { selectComingEventsLimit, selectComingEventsType } from "../store/settings/settings.selectors"
import {
  selectHasDependentAccounts,
  selectHasUserCompletePersonalData,
  selectIsEpuapReSignFlowRequired,
  selectUser
} from "../store/user/user.selectors"
import BuyVoucherSummary from "../components/buyVoucher/BuyVoucherSummary.component"
import ComingConsAndExamsCarousel from "../components/comingConsAndExamsCarousel/ComingConsAndExamsCarousel.component"
import AppDialog from "../components/common/appDialog/AppDialog.component"
import ConsultationsLoader from "../components/common/lottieAnimations/animations/ConsultationsLoader.component"
import PageSection from "../components/common/pageSection/PageSection.component"
import ComingConsultationsList from "../components/consultation/comingConsultationsList/ComingConsultationsList.component"
import ComingNearestConsultationPopup
  from "../components/consultation/comingNearestConsultationPopup/ComingNearestConsultationPopup.component"
import {
  ConsultationListParamsSort,
  ConsultationListParamsStatus,
  ConsultationStatusNumber,
  GetConsultationsListParams,
} from "../components/consultation/Consultation.types"
import {
  getConsultations,
  getLatestConsultationId,
  LATEST_CONSULTATION_ITEMS_LIMIT,
} from "../components/consultation/Consultation.utils"
import LatestConsultationsSection
  from "../components/consultation/latestConsultationsSection/LatestConsultationsSection.component"
import EmptyConsultationsBox from "../components/emptyConsultationsBox/EmptyConsultationsBox.component"
import ComingExams from "../components/exam/comingExams/ComingExams.component"
import FillUserDataInformationPanel
  from "../components/informationPanel/fillUserDataInformationPanel/FillUserDataInformationPanel.component"
import StartEpuapReSignFlow from "../components/startEpuapReSignFlow/StartEpuapReSignFlow"
import VoucherCodeToBuy from "../components/voucher/voucherCodeToBuy/VoucherCodeToBuy.component"
import { canBuyVoucherCode } from "../components/voucher/voucherCodeToBuy/VoucherCodeToBuy.utils"
import { checkFeatureAvailability } from "../store/clinic/clinicSettings.utils"
import { filterOutdatedExams } from "../utils/filterOutdatedExams"
import { redirectToError500Page } from "../utils/handleErrors"
import { scrollToElementId } from "../utils/scrollTo"
import { RoutePath } from "../routes/Routes.types"
import { ComingEventType } from "../store/settings/settings.types"

interface HomePageQuery {
  lcpage: string | null
}

interface HomePageParams {}

const HomePage: FC<HomePageParams> = () => {
  const lcpage: HomePageQuery["lcpage"] = useQueryParam("lcpage")
  const {t, i18n} = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const { B2BClinic } = useIsB2BClinic()
  const voucherCodeToBuy = checkFeatureAvailability().voucherCodeToBuy
  const canBuyVoucher = canBuyVoucherCode()

  const magicLinkExpired = useAppSelector(selectMagicLinkExpired)
  const [magicLinkExpiredPopupOpen, setMagicLinkExpiredPopupOpen] = useState<boolean>(magicLinkExpired)
  const [consultationsLoading, setConsultationsLoading] = useState<boolean>(false)
  const [comingConsultationsListLoading, comingConsultationsListSetLoading] = useState<boolean>(false)
  const [reloadingLatestConsultations, setReloadingLatestConsultations] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const {push} = useHistory()

  const comingEventsType = useAppSelector(selectComingEventsType)
  const comingEventsLimit = useAppSelector(selectComingEventsLimit)
  const cancelledVisible = useAppSelector(selectCancelledConsultationsVisible)
  const examsLoading = useAppSelector(selectComingExamsLoading)
  const userId = useAppSelector(selectUserId)
  const currentUser = useAppSelector(selectUser)
  const comingConsultations = useAppSelector(selectComingConsultations)
  const latestConsultations = useAppSelector(selectLatestConsultations)
  const exams = useAppSelector(selectComingExamsItems)
  const filteredExams = filterOutdatedExams(exams)
  const carouselMode = comingEventsType === ComingEventType.ALL && (comingConsultations.length + exams.length > 1) && !isMdUp
  const hasUserCompletePersonalData = useSelector(selectHasUserCompletePersonalData)
  const isEpuapReSignFlowRequired = useSelector(selectIsEpuapReSignFlowRequired)
  const wasEpupaResignTriedOnLogin =  sessionStorage.getItem(wasEpuapReSignFlowTriedOnLogin)
  const hasDependentAccounts = useSelector(selectHasDependentAccounts)
  const wasSwitchAccountPageShown = sessionStorage.getItem(wasSwitchAccountPageShownOnLogin)
  const {
    clinicAllianzSettings: { ewkaVerificationEnabled, onyxVerificationEnabled }
  } = useAppSelector(selectClinicSettings)

  const getComingConsultations = async (cancelToken?: CancelTokenSource["token"]) => {
    const params: GetConsultationsListParams = {
      sort: ConsultationListParamsSort.ASC,
      status: ConsultationListParamsStatus.PLANNED,
      limit: comingEventsLimit,
      lang: i18n.language
    }
    return getConsultations(params, cancelToken)
  }

  const getLatestConsultations = async (lcpage: number|null, cancelToken?: CancelTokenSource["token"], skipFinishedConsultations = false) => {
    const page = lcpage || 1
    const params: GetConsultationsListParams = {
      limit: LATEST_CONSULTATION_ITEMS_LIMIT * page,
      lang: i18n.language
    }

    if (cancelledVisible || skipFinishedConsultations) {
      params.status = ConsultationListParamsStatus.ARCHIVED
    } else {
      params.statusNumber = [ConsultationStatusNumber.FINISHED]
    }

    return getConsultations(params, cancelToken)
  }

  const getAllConsultations = async (cancelToken?: CancelTokenSource["token"]) => {
    setConsultationsLoading(true)
    const lcNumPage = lcpage ? Number(lcpage) : null

    try {
      const [comingResponse, latestResponse] = await Promise.all([
        getComingConsultations(cancelToken),
        getLatestConsultations(lcNumPage, cancelToken),
      ])
      dispatch(setComingConsultationsTotal(comingResponse.data?.total || 0))
      dispatch(setComingConsultations(comingResponse.data?.items || []))
      dispatch(setLatestConsultationsTotal(latestResponse.data?.total || 0))
      dispatch(setLatestConsultations(latestResponse.data?.items || []))
      // until we set loading to false, we don't see consultations list, so we can't scroll view

      if (comingResponse.data?.total > 0 || latestResponse.data?.total > 0) {
        setConsultationsLoading(false)
        // when we enter home page with lcpage param in search query, we scroll to the possibly last seen "latest"
        // consultation on the list - first consultation from the last loaded page
        // When user loads next pages, we do not scroll
        if (lcNumPage && lcNumPage > 1 && latestResponse.data?.items.length) {
          const consultationIndex = ((lcNumPage - 1) * LATEST_CONSULTATION_ITEMS_LIMIT)
          scrollToElementId(getLatestConsultationId(consultationIndex))
        }
        // e/o scroll to last latest consultations page
      } else {
        await dispatch(setCancelledVisible(true))
        setReloadingLatestConsultations(true)
      }
    } catch (e) {
      if (api.isCancel(e)) return
      redirectToError500Page(e)
      console.error(e)
    }
    setConsultationsLoading(false)
  }

  useEffect(() => {
    const requestSource = (api as ApiInstance).CancelToken.source()

    if (userId) {
      getAllConsultations(requestSource.token)
      dispatch(getComingExams())
    }
    return () => {
      setConsultationsLoading(false)
      requestSource.cancel("Request interrupted by page change")
      dispatch(clearComingConsultations())
      dispatch(clearLatestConsultations())
      dispatch(clearComingExams())
    }
  }, [userId])

  useEffect(() => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_CONSULTATIONS_PAGE_LOAD)
    sessionStorage.removeItem("checkPersonalData")
  }, [])

  useEffect(() => {
    if (!currentUser
        || !hasDependentAccounts
        || wasSwitchAccountPageShown
        || currentUser.user_parent
    ) {
      return
    }
  
    sessionStorage.setItem(wasSwitchAccountPageShownOnLogin, "true")
    push(`/${i18next.language}${RoutePath.SWITCH_ACCOUNT}`)
  }, [hasDependentAccounts, currentUser])

  if (examsLoading || consultationsLoading || !userId || !currentUser) {
    return (
      <Box className="page-loader-box">
        <ConsultationsLoader/>
      </Box>
    )
  }

  const isAllianzEnabled = ewkaVerificationEnabled || onyxVerificationEnabled
  const isEmptyConsultations = !comingConsultations.length
      && !latestConsultations.length
      && !exams.length

  const isAllianzEmptyConsultations = isEmptyConsultations && isAllianzEnabled

  if (isEmptyConsultations && !reloadingLatestConsultations) {
    if (voucherCodeToBuy && canBuyVoucher && !isAllianzEnabled) {
      return <BuyVoucherSummary hideBackBtn={true}/>
    }

    return <EmptyConsultationsBox B2BClinic={B2BClinic} />
  }

  if (isAllianzEmptyConsultations && hasUserCompletePersonalData) {
    return <EmptyConsultationsBox B2BClinic={B2BClinic} />
  }

  if (isEpuapReSignFlowRequired && wasEpupaResignTriedOnLogin !== "true"){
    return <StartEpuapReSignFlow/>
  }

  if (!hasUserCompletePersonalData) {
    if (isAllianzEmptyConsultations) {
      return <EmptyConsultationsBox B2BClinic={B2BClinic} />
    }

    return <FillUserDataInformationPanel 
      showPhoneNumber={checkFeatureAvailability().showPhoneNumberOnCompleteDataView}
      redirectRoute={`/${i18next.language}${RoutePath.MAKE_CONSULTATION}`} 
    />
  }

  return (
    <main>
      {voucherCodeToBuy && canBuyVoucher && <VoucherCodeToBuy voucherCodeToBuy={voucherCodeToBuy} />}
      {(comingConsultations.length > 0 || exams.length > 0) && (
        <PageSection title={t("soon")}>
          <Box
            pl={!isMdUp ? 2 : 0}
            pr={(!carouselMode) ? 2 : 0}
          >
            {
              carouselMode
                ? <ComingConsAndExamsCarousel consultations={comingConsultations} exams={filteredExams} setLoading={comingConsultationsListSetLoading} B2BClinic={B2BClinic} />
                : (
                  <>
                    <ComingConsultationsList setLoading={comingConsultationsListSetLoading} loading={comingConsultationsListLoading} items={comingConsultations} B2BClinic={B2BClinic} />
                    <ComingExams items={filteredExams}/>
                  </>
                )
            }
          </Box>
        </PageSection>
      )}

      <Box
        pl={!isMdUp ? 2 : 0}
        pr={2}
      >
        <LatestConsultationsSection
          lcpage={Number(lcpage) || null}
          reloadingLatestConsultations={reloadingLatestConsultations}
          setReloadingLatestConsultations={(reloading: boolean) => setReloadingLatestConsultations(reloading)}
          B2BClinic={B2BClinic}
        />
      </Box>

      { comingConsultations.length > 0 &&
        <ComingNearestConsultationPopup/>
      }
      {
        magicLinkExpired && (
          <AppDialog
            open={magicLinkExpiredPopupOpen}
            title={t("consultation:archiveTags:otherError")}
            onClose={() => {
              setMagicLinkExpiredPopupOpen(false)
              dispatch(setMagicLinkExpired(false))
            }}
            closeButtonText={t("close")}
          >
            {t("magicLinkExpired")}
          </AppDialog>
        )
      }
    </main>
  )
}

export default HomePage
