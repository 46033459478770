import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Box, Button, Divider, Link, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"
import { push } from "connected-react-router"
import i18next from "i18next"

import api from "../../api/api"
import { postVouchersBuyConfig } from "../../api/routes"
import moneyImg from "../../assets/images/03_zloto.png"
import laptopImg from "../../assets/images/07_laptop.png"
import consultationDateImg from "../../assets/images/consultationDate.png"
import { useQueryParam } from "../../hooks/useSearchParams"
import { selectUser } from "../../store/user/user.selectors"
import ImageByIntegrationType from "../imageByIntegrationType/ImageByIntegrationType"
import ButtonLoader from "../common/buttonLoader/ButtonLoader.component"
import PageSection from "../common/pageSection/PageSection.component"
import SafeDataInfoLabel from "../common/safeDataInfoLabel/SafeDataInfoLabel.component"
import VoucherCodeToBuyLogo from "../voucher/voucherCodeToBuy/VoucherCodeToBuyLogo.component"
import { checkFeatureAvailability } from "../../store/clinic/clinicSettings.utils"
import { getFormattedBirthDate } from "../../utils/getFormattedBirthDate"
import { redirectToError500Page } from "../../utils/handleErrors"
import { VoucherTypes } from "../voucher/voucherBox/VoucherBox.utils"
import {
  canBuyVoucherCode,
  getVoucherDescriptionTranslationKeyByCode
} from "../voucher/voucherCodeToBuy/VoucherCodeToBuy.utils"
import { voucherCost, voucherDescriptionTranslationText, voucherExpirationInMonths } from "./BuyVoucherSummary.utils"
import { buyVoucherSummaryStyles } from "./BuyVoucherSummary.styles"

interface BuyVoucherSummaryProps {
  hideBackBtn?: boolean;
}

const BuyVoucherSummary: FC<BuyVoucherSummaryProps> = ({ hideBackBtn }) => {
  const { t, i18n } = useTranslation()
  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const userData = useSelector(selectUser)
  const dispatch = useDispatch()
  const withoutRedirect = useQueryParam("withoutRedirect")
  const classes = buyVoucherSummaryStyles()
  
  if (userData === null) {
    return null
  }

  const { first_name, last_name, pesel, birth_date } = userData
  const obfuscatePesel = pesel ? `********${pesel.slice(8)}` : ""
  const voucherCodeToBuy = checkFeatureAvailability().voucherCodeToBuy as string
  const canBuyVoucher = canBuyVoucherCode()
  const isTelemediGo = voucherCodeToBuy === VoucherTypes.TYPE_TELEMEDI_GO
  const isTelemediGoForSpecialistsEnabled = checkFeatureAvailability().isTelemediGoForSpecialistsEnabled 
      && isTelemediGo

  const goToHomepage = () => {
    dispatch(push(`/${i18n.language}`))
  }

  if (!voucherCodeToBuy || !canBuyVoucher) {
    goToHomepage()

    return null
  }

  const buyVoucherSubmit = async () => {
    setButtonDisabled(true)

    try {
      const result = await api.request({
        ...postVouchersBuyConfig,
        data: {
          voucher_code_to_buy: voucherCodeToBuy,
          locale: i18next.language
        }
      })

      if (result?.data?.url) {
        window.location.href = result?.data?.url
        // + `?redirect-url=${window.location.origin}/${i18n.language}/voucher/payment/success?voucherCode=${voucherCodeToBuy}`

        return
      }
    } catch (e) {
      redirectToError500Page(e)
    }
  }

  const getPatientDetailsSection = () => (
    <Box className={clsx(classes.section, classes.yourDataSection)}>
      <Box>
        <Typography className={clsx(classes.sectionTitle, classes.sectionYourDataTitle)}>
          { t("finances:vouchers:yourData") }
        </Typography>
        <Box className={classes.sectionYourData}>
          <Typography className={clsx(classes.sectionTitle, classes.sectionYourDataText)}>
            {first_name} {last_name}
          </Typography>
          <Typography className={clsx(classes.sectionTitle, classes.sectionYourDataText)}>
            {t("user:pesel")}: {obfuscatePesel}
          </Typography>
          { birth_date && (
            <Typography className={clsx(classes.sectionTitle, classes.sectionYourDataText)}>
              {t("login:magicLinkBirthdatePasswordLabel")}: { getFormattedBirthDate(new Date(birth_date)) }
            </Typography>
          )}
        </Box>
        <SafeDataInfoLabel/>

        <Box className={clsx(
          classes.section,
          classes.promotionRulesSection,
          isTelemediGoForSpecialistsEnabled
            ? classes.sectionForSpecialists
            : null
        )}>
          <Link
            className={classes.promotionRules}
            color="textPrimary"
            href={ voucherDescriptionTranslationText(
              isTelemediGoForSpecialistsEnabled 
                ? "specialists:regulationsUrl"
                : "regulationsUrl", 
              voucherCodeToBuy
            )}
            target="_blank"
            underline="always"
          >
            { voucherDescriptionTranslationText(
              isTelemediGoForSpecialistsEnabled  ? 
                "specialists:regulationsTitle"
                : "regulationsTitle", 
              voucherCodeToBuy
            )}
          </Link>
        </Box>
        { isTelemediGoForSpecialistsEnabled && (
          <Box>
            <Link
              className={classes.promotionRules}
              color="textPrimary"
              href={ voucherDescriptionTranslationText("regulationsUrl", voucherCodeToBuy) }
              target="_blank"
              underline="always"
            >
              { voucherDescriptionTranslationText("regulationsTitle", voucherCodeToBuy) }
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  )

  return (
    <PageSection>
      <Box className={clsx(classes.pageSection)}>
        <Typography
          variant={isMdUp ? "h2" : "h3"}
          className={classes.pageTitle}
          component="h2"
        >
          { t("finances:vouchers:yourOffer") }
        </Typography>

        <Box className={clsx(classes.sectionPatientDetails)}>
          <Box className={clsx(classes.section, classes.voucherLogoSection)}>
            <VoucherCodeToBuyLogo voucherCode={voucherCodeToBuy} 
              justifyContent={"flex-start"} 
              showDescription={false}
            />
          </Box>

          {isMdUp && getPatientDetailsSection()}
        </Box>

        <Box className={classes.infoSectionsWrapper}>

          <Box className={classes.section}>
            <Box className={classes.imageWrapper}>
              <ImageByIntegrationType
                imageSrc={consultationDateImg}
                alt="dateImage"
                className={classes.image}
                imagePath={"consultationDate.png"}
              />
            </Box>

            <Box>
              <Typography className={clsx(classes.sectionTitle, classes.sectionVoucherDetailsTitle)}>
                { voucherDescriptionTranslationText("title1", voucherCodeToBuy) }
              </Typography>
              <Typography component="time" className={classes.whenSection}>
                <span className={classes.text}>
                  { voucherDescriptionTranslationText("feature1", voucherCodeToBuy, {
                    months: voucherExpirationInMonths(voucherCodeToBuy),
                  }) }
                </span>
                <span className={classes.text}>
                  { voucherDescriptionTranslationText("feature2", voucherCodeToBuy, {
                    date: getFormattedBirthDate(new Date(), voucherExpirationInMonths(voucherCodeToBuy))
                  }) }
                </span>
              </Typography>
            </Box>
          </Box>

          <Box className={classes.section}>
            <Box className={classes.imageWrapper}>
              <ImageByIntegrationType
                imageSrc={laptopImg}
                alt="laptopImage"
                className={classes.image}
                imagePath={"07_laptop.png"}
              />
            </Box>

            <Box>
              <Typography className={clsx(classes.sectionTitle, classes.sectionVoucherDetailsTitle)}>
                { voucherDescriptionTranslationText("title2", voucherCodeToBuy) }
              </Typography>
              {isTelemediGo && (
                t(`finances:voucherCodeToBuy:telemedi-go:customInfo:${getVoucherDescriptionTranslationKeyByCode("summaryFeatureDescription")}`)
              )}
              <Typography component={"ul"} className={classes.ulDescription}>
                <li>
                  <span className={classes.text} dangerouslySetInnerHTML={{ __html:
                    voucherDescriptionTranslationText(
                      getVoucherDescriptionTranslationKeyByCode("description1", voucherCodeToBuy),
                      voucherCodeToBuy
                    )
                  }}>
                  </span>
                </li>
                <li>
                  <span className={classes.text} dangerouslySetInnerHTML={{ __html:
                    voucherDescriptionTranslationText(
                      getVoucherDescriptionTranslationKeyByCode("description2", voucherCodeToBuy),
                      voucherCodeToBuy
                    ) 
                  }}>
                  </span>
                </li>
                { isTelemediGo && (
                  <li>
                    <span className={classes.text} dangerouslySetInnerHTML={{ __html:
                      voucherDescriptionTranslationText(
                        getVoucherDescriptionTranslationKeyByCode("description4", voucherCodeToBuy),
                        voucherCodeToBuy
                      ) 
                    }}>
                    </span>
                  </li>
                )}
              </Typography>
            </Box>
          </Box>
          
          <Box className={classes.section}>
            <Box className={classes.imageWrapper}>
              <ImageByIntegrationType
                imageSrc={moneyImg}
                alt="moneyImage"
                className={classes.image}
                imagePath={"03_zloto.png"}
              />
            </Box>

            <Box>
              <Typography className={clsx(classes.sectionTitle, classes.sectionVoucherDetailsTitle)}>
                { voucherDescriptionTranslationText("title3", voucherCodeToBuy) }
              </Typography>
              <Typography>
                <span className={classes.text}>
                  { voucherDescriptionTranslationText("description3", voucherCodeToBuy, {
                    cost: voucherCost(voucherCodeToBuy)
                  }) }
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider color={theme.palette.grey["100"]}/>

        {!isMdUp && getPatientDetailsSection()}

        <Box className={classes.buttonsWrapper}>
          <Button
            variant="contained"
            color={"secondary"}
            className={classes.button}
            onClick={buyVoucherSubmit}
            disabled={isButtonDisabled}
            startIcon={isButtonDisabled && (<ButtonLoader position="prefix"/>)}
          >
            { voucherDescriptionTranslationText("cost", voucherCodeToBuy, {
              cost: voucherCost(voucherCodeToBuy, true)
            }) }
          </Button>
          { !hideBackBtn && !withoutRedirect && (
            <Button
              variant="outlined"
              color={"primary"}
              className={classes.button}
              onClick={goToHomepage}
            >
              { t("consultation:back") }
            </Button>
          )}
        </Box>
      </Box>
    </PageSection>
  )
}

export default BuyVoucherSummary
