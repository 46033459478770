import React, {FC, useEffect, useState} from "react"
import TagManager from "react-gtm-module"
import {useTranslation } from "react-i18next"
import {useSelector} from "react-redux"
import {RouteComponentProps, useHistory, useParams} from "react-router"
import {Box, Button, Typography, useMediaQuery, useTheme} from "@material-ui/core"
import { CancelTokenSource } from "axios"

import api, { ApiInstance } from "../../api/api"
import {getConsultationConfig} from "../../api/routes"
import paymentSuccessfulImage from "../../assets/images/payment-successful.png"
import { analytics, LogEventType } from "../../services/analytics"
import {selectToken} from "../../store/session/session.selectors"
import ConsultationsLoader from "../../components/common/lottieAnimations/animations/ConsultationsLoader.component"
import {ConsultationModel, ConsultationType} from "../../components/consultation/Consultation.types"
import ImageByIntegrationType from "../../components/imageByIntegrationType/ImageByIntegrationType"
import {useSuccessfulPaymentPageStyles } from "../../components/successfulPayment/SuccessfulPayment.styles"
interface ConsultationSuccessfulPaymentPageParams {
  consultationId: string;
}

const ConsultationSuccessfulPaymentPage: FC<RouteComponentProps<ConsultationSuccessfulPaymentPageParams>> = () => {
  const {consultationId} = useParams<ConsultationSuccessfulPaymentPageParams>()

  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const classes = useSuccessfulPaymentPageStyles()
  const {push} = useHistory()

  const [loading, setLoading] = useState(true)
  const [consultationData, setConsultationData] = useState<ConsultationModel>()
  const tokenData = useSelector(selectToken)
  const consultationType = consultationData?.consultationType
  const shouldGoToSurveyPage = consultationType === ConsultationType?.PRESCRIPTION_ONLY || consultationType === ConsultationType?.SICK_LEAVE
  const getConsultationData = async (cancelToken?: CancelTokenSource["token"]) => {
    try {
      const {data} = await api.request<ConsultationModel>({
        ...getConsultationConfig(consultationId),
        cancelToken,
      })
      setConsultationData(data)

      if (data) {
        TagManager.dataLayer({
          dataLayer: {
            event: "transaction",
            userId: data.patientId,
            consultationId: consultationId,
            ecommerce: {
              purchase: {
                actionField: {
                  id: consultationId,
                  affiliation: "Telemedi.co",
                  revenue: data.cost,
                  currency_code: data.currency,
                  Tax: "",
                  shipping: "0.00",
                  coupon: data.subscription_code,
                },
                products: [
                  {
                    name: data.doctorName,
                    id: data.doctorId,
                    price: data.cost,
                    brand: "Telemedi.co",
                    category: data.specialization.name,
                    variant: data.type.replace("consultation.type.", ""),
                    quantity: 1,
                    dimension1: "Pacjent Telemedico",
                  },
                ]
              }
            }
          }
        })
      }
    } catch (e) {
      if (api.isCancel(e)) return
      console.error(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    const requestSource = (api as ApiInstance).CancelToken.source()

    if (tokenData && !consultationData) {
      getConsultationData(requestSource.token)
    }

    return () => {
      setLoading(false)
      requestSource.cancel("Request interrupted by page change")
    }
  }, [consultationId, tokenData, consultationData])

  const goToHomepage = () => {
    analytics.sendEventWithDefaultParams(LogEventType.BACK_TO_HOME_PAGE_CLICK)
    push(`/${i18n.language}`)
  }

  const redirectToPage = () => {
    analytics.sendEventWithDefaultParams(LogEventType.GO_TO_CONSULTATION_CLICK)
    switch (consultationType) {
      case ConsultationType?.PRESCRIPTION_ONLY:
        return push(`/${i18n.language}/medical-survey/${consultationId}`)
      case ConsultationType?.SICK_LEAVE:
        return push(`/${i18n.language}/sick-leave-survey/${consultationId}`)
      default:
        return push(`/${i18n.language}/consultation/${consultationId}`)
    }
  }

  if (loading || !consultationData) {
    return (
      <Box className="page-loader-box">
        <ConsultationsLoader/>
      </Box>
    )
  }

  const imageBox = (
    <Box className={classes.imageWrapper}>
      <ImageByIntegrationType
        imageSrc={paymentSuccessfulImage}
        alt="paymentSuccessfulImage"
        className={classes.image}
        imagePath={"payment-successful.png"}
      />
    </Box>
  )

  const getSuccessPaymentTitle = () => {
    if (false === consultationData?.sickLeaveQuestionnaireFilled) {
      return t("payments:successPayment:consultationTitle")
    }
    return t("payments:successPayment:title")
  }

  return (
    <Box
      className={classes.pageSection}
      component="section"
    >
      <Box className={classes.content}>
        <Typography
          variant={isMdUp ? "h2" : "h4"}
          component="h2"
          className={classes.title}
        >
          { getSuccessPaymentTitle() }
        </Typography>

        { !isMdUp &&
          imageBox
        }

        <Box mb={2}>
          <Typography variant={isMdUp ? "h4" : "h5"} component="span">
            {t("payments:successPayment:caption")}
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography className="break-spaces">
            {t("payments:successPayment:consultationsDescription")}
          </Typography>
        </Box>
        <Box className={classes.buttonsWrapper}>

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={redirectToPage}
          >
            {t(shouldGoToSurveyPage ? "prescription:fillMedicalSurvey" : "payments:successPayment:goToConsultationPage")}
          </Button>
        </Box>
      </Box>

      { isMdUp &&
        imageBox
      }
    </Box>
  )
}

export default ConsultationSuccessfulPaymentPage
