import React, { FC, useEffect,useMemo,useState } from "react"
import { Controller, FormProvider,useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"

import api from "../../../api/api"
import { getUserDataConfig, patchUserPersonalDataConfig, registerUserParentConfig, updateUserParentPersonalDataConfig } from "../../../api/routes"
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks"
import useGetFormValidationRules from "../../../hooks/useGetFormValidationRules"
import { useUserIsUnderage } from "../../../hooks/useUserIsUnderage"
import { analytics, LogEventType } from "../../../services/analytics"
import { selectClinicSettings } from "../../../store/clinic/clinicSettings.selectors"
import { selectUserId } from "../../../store/session/session.selectors"
import { selectUser } from "../../../store/user/user.selectors"
import { setUserData } from "../../../store/user/user.slice"
import { isAdultFromPesel, isValidBirthDate, isValidPesel } from "../../user/userProfile/userPersonalData/UserPersonalDataEditModal/UserPersonalDataEditModal.validation"
import Alert from "../../alert/Alert.component"
import ButtonLoader from "../../common/buttonLoader/ButtonLoader.component"
import LoaderBox from "../../common/loaderBox/LoaderBox.component"
import SafeDataInfoLabel from "../../common/safeDataInfoLabel/SafeDataInfoLabel.component"
import DisabledFormFieldTooltip
  from "../../commonFormItems/disabledFormFieldTooltip/DisabledFormFieldTooltip.component"
import UserAddressDataEditForm from "../../user/userProfile/userAddressData/UserAddressDataEditForm/UserAddressDataEditForm.component"
import { UserParentAddressDataEditForm } from "../../user/userProfile/userParentAddressData/UserParentAddressDataEditForm/UserParentAddressDataEditForm.component"
import UserParentPersonalDataEditForm from "../../user/userProfile/userParentPersonalData/UserParentPersonalDataEditForm/UserParentPersonalDataEditForm.component"
import UserPersonalDataEditForm from "../../user/userProfile/userPersonalData/UserPersonalDataEditForm/UserPersonalDataEditForm.component"
import FillUserDataCountDown from "./fillUserDataCountDown/FillUserDataCountDown.component"
import { checkFeatureAvailability } from "../../../store/clinic/clinicSettings.utils"
import { handleReactHookFormErrors, redirectToError500Page } from "../../../utils/handleErrors"
import { isAdult } from "../../../utils/isAdult"
import { getUserDocumentData } from "../../user/userProfile/userPersonalData/UserPersonalData.utils"
import {
  ErrorFields,
  useFillUserDataInformationPanel,
  useUserFormDataDefaultValues
} from "./FillUserDataInformationPanel.utils"
import { Gender, UserDocumentData } from "../../../store/user/user.types"
import { CountryOption } from "../../commonFormItems/countrySelect/Country.types"
import { TimeZoneOption } from "../../commonFormItems/timeZoneSelect/TimeZoneSelect.types"
import { useInformationPanelStyles } from "../InformationPanel.styles"

import { userDataValidationSchema } from "./UserData.validation"

export interface UserDataValues {
  underageUserCheckbox: boolean;
  sameAddressCheckbox: boolean;
  firstName: string;
  lastName: string;
  nationality: CountryOption["value"] | null;
  pesel: string;
  documentType: UserDocumentData["value"] | null;
  identityNumber: string;
  birthDate: string;
  gender: Gender | null;
  defaultTimezone: TimeZoneOption | null;
  street: string;
  houseNumber: string;
  apartmentNumber: string;
  postalCode: string;
  city: string;
  country: string;
  parentFirstName: string;
  parentLastName: string;
  parentNationality: CountryOption["value"] | null;
  parentPesel: string;
  parentDocumentType: UserDocumentData["value"] | null;
  parentIdentityNumber: string;
  parentBirthDate: string;
  parentGender: Gender | null;
  parentDefaultTimezone: TimeZoneOption | null;
  parentStreet: string;
  parentHouseNumber: string;
  parentApartmentNumber: string;
  parentPostalCode: string;
  parentCity: string;
  parentCountry: string;
  phoneCountryCode: string;
  phoneNumber: string;
  showPhoneNumber?: boolean;
  redirectRoute?: string;
}

interface FillUserDataInformationPanelProps {
  showPhoneNumber?: boolean,
  redirectRoute?: string
}

type InputProps = {
  disabled: boolean
  endAdornment: JSX.Element | undefined
}

export const disabledFormFieldTooltipInputProps = (field?: string | null, loading?: boolean, ...shouldDisable: boolean[]) => {
  const otherVariables = (shouldDisable.length && shouldDisable.some((value)=>value))

  return {
    disabled: !!field || loading || otherVariables,
    endAdornment: ( field || otherVariables )
      ? (
        <InputAdornment position="end">
          <DisabledFormFieldTooltip/>
        </InputAdornment>
      ) : undefined,
  }
}

export const resolveDisableInputProps = (
  callback: any,
  skip = false
): InputProps | undefined => {
  if (skip) {
    return undefined
  }

  return callback()
}

const FillUserDataInformationPanel: FC<FillUserDataInformationPanelProps> = ({showPhoneNumber, redirectRoute}) => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const { t, i18n } = useTranslation()
  const classes = useInformationPanelStyles()

  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

  const userData = useAppSelector(selectUser)
  const userParentData = userData?.user_parent ?? null
  const userId = useSelector(selectUserId)
  const fillPersonalDataDeadline = userData?.fillPersonalDataDeadline 
    ? new Date(Number(userData.fillPersonalDataDeadline) * 1000) 
    : null
  const fillPersonalDataTimeLimitToDisplayMs = userData?.fillPersonalDataTimeLimitToDisplayMs
  const showTimer = !!fillPersonalDataDeadline && !!fillPersonalDataTimeLimitToDisplayMs
  const userDocumentData = useMemo(() => getUserDocumentData(userData), [])
  const userParentDocumentData = useMemo(() => getUserDocumentData(userParentData), [])
  const underageUser = useUserIsUnderage()
  const { defaultValues } = useUserFormDataDefaultValues()
  const [loading, setLoading] = useState(false)

  const {
    frontendSettings: { modules },
    clinicAllianzSettings: {
      ewkaVerificationEnabled,
      onyxVerificationEnabled
    }
  } = useAppSelector(selectClinicSettings)

  const isAllianz = ewkaVerificationEnabled || onyxVerificationEnabled
  const isDifferentDateFormatEnabled = modules.includes("enable_different_date_format")
  const canPatientEditDataUntilFirstConsultationFinished = modules.includes("enable_data_update") && !userData?.isSensitiveDataEditingDisabled

  if (!userData) {
    return <LoaderBox />
  }

  const form = useForm<UserDataValues>({
    defaultValues,
    resolver: useGetFormValidationRules(() => userDataValidationSchema(
      userData.pesel, 
      userParentData?.pesel ?? null,
      isDifferentDateFormatEnabled
    )),
    mode: "all",
  })

  const {
    handleChange,
    sameAddressOn,
    userAddressFields,
    userParentAddressFields,
    getPatientPayloadData,
    getParentPayloadData
  } = useFillUserDataInformationPanel(form)
  const watchUserAddressFields = useWatch({ control: form.control, name: userAddressFields })
  const underageUserChecked = useWatch({ control: form.control, name: "underageUserCheckbox" })
  const peselValue = useWatch({ control: form.control, name: "pesel" })
  const birthDateValue = useWatch({ control: form.control, name: "birthDate" })
  const peselIsValid = !!peselValue && isValidPesel(peselValue)
  const birthDateIsValid = !!birthDateValue && isValidBirthDate(birthDateValue)
  const benefitSystemIntegrationEnabled = checkFeatureAvailability().enableBenefitSystemIntegration // todo change to general checkboxuserData)

  const onSubmit = form.handleSubmit(async (values) => {
    analytics.sendEventWithDefaultParams(LogEventType.USER_DATA_BUTTON_SAVE_CLICK)
    const patientData = getPatientPayloadData(values, userData, !!showPhoneNumber, isDifferentDateFormatEnabled, canPatientEditDataUntilFirstConsultationFinished)
    const parentData = getParentPayloadData(values, userParentData, isDifferentDateFormatEnabled, canPatientEditDataUntilFirstConsultationFinished)

    setLoading(true)

    try {
      await api.request({
        ...patchUserPersonalDataConfig(userId),
        data: patientData
      })

      if(values.underageUserCheckbox) {
        if (!userParentData) {
          await api.request({
            ...registerUserParentConfig,
            data: parentData,
          })
        } else {
          await api.request({
            ...updateUserParentPersonalDataConfig,
            data: parentData,
          })
        }
      }

      analytics.sendEventWithDefaultParams(LogEventType.USER_DATA_SAVE_SUCCESS)
      const { data } = await api.request({ ...getUserDataConfig(userId) })
      dispatch(setUserData(data))

      if (redirectRoute) {
        push(redirectRoute)
      }
    } catch (e) {
      const errorData = e.response?.data
      analytics.sendEventWithDefaultParams(LogEventType.USER_DATA_SAVE_ERROR, {
        "error_code": errorData?.status,
        "error_name": errorData?.title
      })
      console.error(e)
      redirectToError500Page(e)
      if (e.response?.data?.errors) {
        if (e.response?.data?.errors?.["address.postal_code"]) {
          form.setError(
            ErrorFields["address.postal_code"],
            {
              type: "manual",
              message: t("errors:This value is not valid.")
            }
          )
        }
        handleReactHookFormErrors(e.response.data.errors, form)
      }
    }

    setLoading(false)
  })

  const goToHomepage = () => {
    analytics.sendEventWithDefaultParams(LogEventType.USER_DATA_BUTTON_CANCEL_CLICK)
    push(`/${i18n.language}`)
  }

  useEffect(() => {
    if(peselIsValid) {
      form.setValue("underageUserCheckbox", !isAdultFromPesel(peselValue))
    } else if (birthDateIsValid) {
      form.setValue("underageUserCheckbox", !isAdult(birthDateValue))
    }
  },[peselValue, birthDateValue])

  useEffect(() => {
    if(sameAddressOn) {
      userParentAddressFields.forEach( (parentAddressField, ind) => {
        form.setValue(parentAddressField, form.getValues(userAddressFields[ind]))
      })
    }
  }, [watchUserAddressFields])

  useEffect(() => {
    form.clearErrors()
  },[underageUserChecked])

  useEffect(() => {
    analytics.sendEventWithDefaultParams(LogEventType.USER_DATA_PAGE_LOAD)
    setTimeout(() => {
      form.trigger()
    }, 1000)
  }, [])

  return (
    <Box
      className={classes.pageSection}
      component="section"
    >
      {showTimer &&
          <FillUserDataCountDown
            endTime={fillPersonalDataDeadline!}
            millisecondsToFill={fillPersonalDataTimeLimitToDisplayMs!}
          />
      }
      {!isAllianz && <Typography
        variant={isMdUp ? "h2" : "h4"}
        component="h2"
        className={classes.pageTitle}
      >
        { t("user:fillInPersonalDataTitle") }
      </Typography>}
      {isAllianz && <Typography
        variant={isMdUp ? "h2" : "h4"}
        component="h2"
        className={classes.pageTitle}
      ><Alert message={t("errors:allianzEmptyPatientData")}/>
      </Typography>}
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <Grid container spacing={8}>
            <Grid item xs={12} md={isAllianz ? 12 : 8} className={classes.gridItem}>
              <Box my={4}>
                {!isAllianz && <Typography variant="body1" color="textSecondary" className="break-spaces">
                  { t("user:fillInPersonalDataCaption") }
                </Typography>}
              </Box>
            </Grid>

            <Grid item xs={12} md={8} className={classes.gridItem}>
              {((underageUser ?? true) && !userData.pesel && !benefitSystemIntegrationEnabled && !showPhoneNumber) ? (
                <>
                  <Box mb={1}>
                    <Typography variant="body1" className="break-spaces">
                      {t("user:underageUserMessage")}
                    </Typography>
                  </Box>
                  <Box mb={4}>
                    <Tooltip title={(peselIsValid || birthDateIsValid) ? t("user:underageCheckboxDisabledTooltip") : ""} placement="bottom-end">
                      <FormControlLabel
                        label={<Typography variant="body2">{t("user:underageUserCheckboxLabel")}</Typography>}
                        control={
                          <Controller
                            name="underageUserCheckbox"
                            control={form.control}
                            render={({ field: {onChange, value}}) => (
                              <Checkbox
                                checked={value}
                                onChange={onChange}
                                checkedIcon={<CheckBoxIcon color={(peselIsValid || birthDateIsValid) ? "disabled" : "primary"}/>}
                                icon={<CheckBoxOutlineBlankIcon/>}
                                disabled={loading || peselIsValid || birthDateIsValid}
                              />
                            )}
                          />
                        }
                      />
                    </Tooltip>
                  </Box>
                </>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={6} className={classes.gridItem}>
              <Typography
                variant="h4"
                component="span"
                className={classes.formSectionTitle}
              >
                {underageUserChecked
                  ? t("user:personalInfoUnderage")
                  : t("user:personalInfo")
                }
              </Typography>
              <UserPersonalDataEditForm
                userData={userData}
                userDocumentData={userDocumentData}
                loading={loading}
                showEmail={checkFeatureAvailability().isUniqaApiIntegrationEnabled}
              />
            </Grid>

            <Grid item xs={12} lg={6} className={classes.gridItem}>
              <Box mt={!isMdUp ? 5.5 : 0}>
                <Typography
                  variant="h4"
                  component="span"
                  className={classes.formSectionTitle}
                >
                  {underageUserChecked
                    ? t("user:addressInfoUnderage")
                    : t("user:addressInfo")
                  }
                </Typography>
                <UserAddressDataEditForm
                  loading={loading}
                  phoneNumber={userData?.phone_national_number}
                  showPhoneNumber={showPhoneNumber}
                  userData={userData}
                  isEditingBlocked={isAllianz}
                />
              </Box>
            </Grid>

            {underageUserChecked && (
              <>
                <Grid item xs={12} lg={6} className={classes.gridItem}>
                  <Box mt={5.5}>
                    <Typography
                      variant="h4"
                      component="span"
                      className={classes.formSectionTitle}
                    >
                      {t("user:parentPersonalInfo")}
                    </Typography>

                    <UserParentPersonalDataEditForm
                      userParentData={userParentData}
                      userParentDocumentData={userParentDocumentData}
                      loading={loading}
                      underageUserChecked={underageUserChecked}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6} className={classes.gridItem}>
                  <Box mt={5.5}>
                    <Typography
                      variant="h4"
                      component="span"
                      className={classes.formSectionTitle}
                    >
                      {t("user:parentAddressInfo")}
                    </Typography>

                    <Box my={3}>
                      <FormControlLabel
                        label={<Typography variant="body2">{t("user:sameAddressLabel")}</Typography>}
                        control={
                          <Controller
                            name="sameAddressCheckbox"
                            control={form.control}
                            render={({ field: {onChange, value}}) => (
                              <Checkbox
                                checked={value}
                                onChange={handleChange(onChange)}
                                checkedIcon={<CheckBoxIcon color="primary"/>}
                                icon={<CheckBoxOutlineBlankIcon/>}
                              />
                            )}
                          />
                        }
                      />
                    </Box>

                    <UserParentAddressDataEditForm
                      loading={loading}
                      disableFields={sameAddressOn || isAllianz}
                      userParentData={userParentData}
                    />
                  </Box>
                </Grid>
              </>
            )}

            <Grid item xs={12} className={classes.gridItem}>
              <Box className={classes.buttonsWrapper}>
                {!isAllianz && <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                  className={classes.button}
                  startIcon={loading ? <ButtonLoader position="prefix"/> : null}
                >
                  {t("save")}
                </Button>}

                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  disabled={loading}
                  className={classes.button}
                  onClick={goToHomepage}
                >
                  {t("cancel")}
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <SafeDataInfoLabel/>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  )
}

export default FillUserDataInformationPanel
