import React, {FC, useEffect, useState} from "react"
import {Controller, FieldErrors, FormProvider, useForm} from "react-hook-form"
import { useTranslation } from "react-i18next"
import {useSelector} from "react-redux"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import {push} from "connected-react-router"
import dayjs from "dayjs"

import api from "../../api/api"
import {
  postConsultationFileConfig,
  sendSickLeaveQuestionnaireAnswers
} from "../../api/routes"
import {useAppDispatch} from "../../hooks/storeHooks"
import useConsultationLang from "../../hooks/useConsultationLang"
import useGetFormValidationRules from "../../hooks/useGetFormValidationRules"
import { analytics, LogEventType } from "../../services/analytics"
import {usePostChatMessageMutation} from "../../store/consultations/consultations.api"
import {selectUserId} from "../../store/session/session.selectors"
import {selectUser} from "../../store/user/user.selectors"
import {Countries} from "../../components/commonFormItems/countrySelect/Country.types"
import ButtonTextUnderlined from "../common/buttonTextUnderlined/ButtonTextUnderlined.component"
import LoaderBox from "../common/loaderBox/LoaderBox.component"
import FileDropzone from "../commonFormItems/fileDropzone/FileDropzone.component"
import TextFieldController from "../commonFormItems/textFieldController/TextFieldController.component"
import EmployerFields from "./EmployerFields.component"
import { ExtraQuestions } from "./ExtraQuestions/ExtraQuestions.component"
import ForeignWorker from "./ForeignWorker.component"
import SickLeaveDates from "./SickLeaveDates.component"
import SickLeavePatientAddress from "./SickLeavePatientAddress.component"
import SickLeaveSurveySummary from "./SickLeaveSurveySummary.component"
import {redirectToError500Page} from "../../utils/handleErrors"
import {getSickLeaveEmploymentTypeLabel} from "../consultation/Consultation.utils"
import {
  EMPLOYER_FIELDS_LIMIT,
  getEmployerDataParams,
  getEmployerDataSummaryMessage,
  getExtraQuestionsAnswersPayload,
  getExtraQuestionsMessage,
  getForeignWorkerData,
  getOtherAddressData,
  handleSavePersonalData,
} from "../SickLeaveSurvey/SickLeaveSurvey.utils"
import {UserSickLeaveEmploymentType} from "../../store/user/user.types"
import {OrderedSickLeaveDetails} from "../consultation/Consultation.types"
import {ChatMessageType} from "../consultationChat/ConsultationChat.types"
import {medicalSurveyFields} from "../medicalSurvey/MedicalSurvey.types"
import { SickLeaveSurveyStatus, SurveyFields } from "../SickLeaveSurvey/SickLeaveSurvey.types"
import {useSickLeaveSectionStyles} from "./SickLeaveSurvey.styles"

import {sickLeaveSurveySchema} from "./SickLeaveSurvey.validation"

interface SickLeaveSurveyProps {
  consultationId: string;
  orderedSickLeave: OrderedSickLeaveDetails;
  countriesData: Countries;
}

const SickLeaveSurvey: FC<SickLeaveSurveyProps> = ({consultationId, orderedSickLeave, countriesData}) => {
  const classes = useSickLeaveSectionStyles()
  const dispatch = useAppDispatch()
  const {t, i18n} = useTranslation()
  const consultationLang = useConsultationLang()
  const userData = useSelector(selectUser)
  const userId = useSelector(selectUserId)
  const {employment_type: employmentType, start_at: startAt, end_at: endAt} = orderedSickLeave
  const disableDateChanging = !!(endAt && startAt)
  const [surveyStatus, setSurveyStatus] = useState(SickLeaveSurveyStatus.START)
  const [surveyValues, setSurveyValues] = useState<SurveyFields>(null)
  const [newStartDate, setNewStartDate] = useState(startAt)
  const [newEndDate, setNewEndDate] = useState(endAt)
  const [addressFieldsDisabled, setAddressFieldsDisabled] = useState(true)
  const [isOtherAddressCheckboxChecked, setIsOtherAddressCheckboxChecked] = useState(true)
  const isSummaryStep = surveyStatus === SickLeaveSurveyStatus.CONFIRM
  const [errors, setErrors] = useState<string[]>([])
  const [file, setFile] = useState<File | null>(null)
  const [isSaving, setSaving] = useState(false)
  const [isPersonalDataSaving, setIsPersonalDataSaving] = useState(false)
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"))
  const [postChatMessage] = usePostChatMessageMutation()
  const isLoading = isPersonalDataSaving || isSaving
  //check employee statuses
  const isUniformedServices = employmentType === UserSickLeaveEmploymentType.UNIFORMED_SERVICES
  const isStudent = employmentType === UserSickLeaveEmploymentType.STUDENT
  const isForeignWorker = employmentType === UserSickLeaveEmploymentType.FOREIGN_COMPANY
  // e/o check employee statuses
  const form = useForm({
    defaultValues: {
      street: userData?.address.street,
      houseNumber: userData?.address.house_number,
      apartmentNumber: userData?.address.apartment_number,
      postalCode: userData?.address.postal_code,
      city: userData?.address.city,
      country: userData?.nationality,
      street_otherAddress: "",
      houseNumber_otherAddress: "",
      apartmentNumber_otherAddress: "",
      postalCode_otherAddress: "",
      city_otherAddress: "",
      country_otherAddress: "PL",
      employer: [{nipItem: "", addressItem: "", nameItem: ""}],
      nipConfirmation: false,
      studentConfirmation: false,
      orderConfirmation1: false,

      // TODO: handle this data https://telemedico.atlassian.net/browse/LESS-3759
      pregnant: "",
      pregnancySickLeave: "",
      chronicDiseases: "",
      chronicDiseasesGroup: [],
      allergy: "",
      allergyDetails: "",
      regularDrugs: "",
      regularDrugsDetails: "",
      duringSickLeave: "",
      ailments: "",
      ailmentsTime: "",
      //ailments optional fields
      coldOrPain: [],
      stomachIssues: [],
      bladderIssues: [],
      injuries: [],
      backPain: [],
      eyesIssues: [],
      migraine: [],
      stressReaction: [],
      menses: [],
      // e/o ailments optional fields
      importantInformations: "",
    },
    mode: "all",
    resolver: useGetFormValidationRules(() => sickLeaveSurveySchema()),
  })

  const handleChangeDates = (start: string, end: string) => {
    setNewStartDate(start)
    setNewEndDate(end)
  }

  const renderCheckboxField = (name: string, label: string) => {
    return (
      <Controller
        name={name}
        render={({
          field: {onChange},
          fieldState: {error},
        }) => (
          <Box mt={2} className={error ? classes.error : ""}>
            <FormControlLabel
              control={
                <Checkbox
                  id={name}
                  onChange={onChange}
                  checkedIcon={<CheckBoxIcon color="primary"/>}
                  icon={<CheckBoxOutlineBlankIcon />}
                  size="medium"
                />
              }
              label={
                <Box>
                  <Typography variant="body1">
                    {label}
                  </Typography>
                </Box>
              }
            />
          </Box>
        )}
      />
    )
  }

  const onSubmit = form.handleSubmit(async (values: SurveyFields) => {
    const dateFormat = "DD/MM/YYYY"
    const otherAddressMessage = getOtherAddressData(values)
    const foreignWorkerData = getForeignWorkerData(values, isForeignWorker)
    const employerDataParams = getEmployerDataParams(values)
    const uniformedServiceMessage = isUniformedServices
      ? `\nRodzaj służb mundurowych: ${values?.uniformedServiceType}` : ""
    const message = `
            ${otherAddressMessage}
            ${uniformedServiceMessage}
            ${foreignWorkerData}
            `
    const periodMessage = `Zwolnienie początek: ${dayjs(newStartDate).format(dateFormat)}, koniec: ${dayjs(newEndDate).format(dateFormat)} \nrodzaj zatrudnienia: ${getSickLeaveEmploymentTypeLabel(employmentType)}`
    const periodParams = {
      start: newStartDate,
      end: newEndDate,
      employment_type: employmentType
    }
    const extraQuestionsMessage = getExtraQuestionsMessage(values)
    const employerDataSummaryMessage = getEmployerDataSummaryMessage(values)
    let response = {}

    if (surveyStatus === SickLeaveSurveyStatus.START) {
      analytics.sendEventWithDefaultParams(LogEventType.SICK_LEAVE_SURVEY_BUTTON_NEXT_CLICK, { "employment_type": employmentType })
      setSurveyStatus(SickLeaveSurveyStatus.CONFIRM)
      setSurveyValues(values)
      return false
    }

    setSaving(true)
    analytics.sendEventWithDefaultParams(LogEventType.SICK_LEAVE_SURVEY_BUTTON_CONFIRM_CLICK, { "employment_type": employmentType})

    if (startAt !== newStartDate || endAt !== newEndDate) { // send new dates if changed
      response = await postChatMessage({
        consultationId,
        messageType: ChatMessageType.SICK_LEAVE_DETAILS_MESSAGE,
        message: periodMessage,
        params: periodParams
      })
    }

    response = await postChatMessage({
      consultationId,
      messageType: ChatMessageType.SICK_LEAVE,
      message,
      isSystemMessage: true,
      params: {
        symptoms: values?.importantInformations,
        employersData: employerDataParams
      }
    })

    response = await postChatMessage({
      consultationId,
      messageType: ChatMessageType.WELCOME,
      message: t("consultation:sickLeave:submitMessage"),
    })

    response = await postChatMessage({
      consultationId,
      messageType: ChatMessageType.HELP,
      isSystemMessage: true,
      message: extraQuestionsMessage,
    })

    response = await postChatMessage({
      consultationId,
      messageType: ChatMessageType.USER,
      isSystemMessage: true,
      message: employerDataSummaryMessage,
    })

    try {
      await api.request({
        ...sendSickLeaveQuestionnaireAnswers,
        data: getExtraQuestionsAnswersPayload(values, consultationId),
      })
    } catch (e) {
      redirectToError500Page(e)
    }

    //TODO: Imo, this function should handle errors.
    if(response?.error) {
      analytics.sendEventWithDefaultParams(LogEventType.SICK_LEAVE_SURVEY_CONFIRM_ERROR, {
        "error_code": response?.error?.status,
        "employment_type": employmentType,
      })
    } else {
      analytics.sendEventWithDefaultParams(LogEventType.SICK_LEAVE_SURVEY_CONFIRM_SUCCESS, { "employment_type": employmentType })
    }

    dispatch(push(`/${i18n.language}`))

    setSaving(false)
  })

  const checkScrollToError = (errors: FieldErrors<medicalSurveyFields>) => {
    const elements = Object.keys(errors).map(name => document.getElementById(name)).filter(el => !!el) || []

    elements.pop()?.scrollIntoView({behavior: "smooth", block: "center"})
  }

  const handleFileChange = (files: File[]) => {
    setFile(files.length ? files[0] : null)
  }

  const handleGoToNextStep = async (event?: React.FormEvent): Promise<void> => {
    event?.preventDefault()
    setErrors([])
    onSubmit?.()

    if (file && surveyStatus === SickLeaveSurveyStatus.CONFIRM) {
      const formData = new FormData()
      formData.append("file", file)

      try {
        await api.request({
          ...postConsultationFileConfig(consultationId),
          data: formData
        })

        setFile(null)
      } catch (e) {
        if (e.response?.data?.errors) {
          setErrors(e.response.data.errors.map((error: string) => consultationLang.getLabel(`errors:${error}`)))
        }
      }
    }
  }

  const handleOnChangeOtherAddressChange = () => {
    if (!isOtherAddressCheckboxChecked) {
      form.setValue("street_otherAddress", "")
    }

    setIsOtherAddressCheckboxChecked(!isOtherAddressCheckboxChecked)
  }

  useEffect(() => {
    checkScrollToError(form.formState.errors)
  }, [form.formState.errors])

  useEffect(() => {
    analytics.sendEventWithDefaultParams(LogEventType.SICK_LEAVE_SURVEY_PAGE_LOAD, { "employment_type": employmentType })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box
      className={classes.pageSection}
    >
      {isLoading && <LoaderBox boxType="fullPage"/>}

      {surveyStatus === SickLeaveSurveyStatus.START && (
        <Box
          width="100%"
          mb={theme.spacing(0.5)}
        >
          <Typography variant="h3">
            {t("consultation:sickLeaveTitle")}
          </Typography>
          {newStartDate && newEndDate && (
            <SickLeaveDates
              startAt={newStartDate}
              endAt={newEndDate}
              handleChangeDates={handleChangeDates}
              disabled={isLoading || disableDateChanging}
            />
          )}
        </Box>
      )}
      <Box
        width="100%"
        className={isLoading ? classes.loading : ""}
      >
        <FormProvider {...form}>
          <Box
            component="form"
            onSubmit={onSubmit}
            width="100%"
          >
            <Box className={surveyStatus !== SickLeaveSurveyStatus.START ? classes.hidden : ""}>
              <Box>
                {
                  isUniformedServices && (
                    <TextFieldController
                      name="uniformedServiceType"
                      label={t("consultation:uniformedServiceType")}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        endAdornment: null,
                      }}
                      className={classes.employerField}
                    />
                  )
                }
                {isForeignWorker &&
                  <ForeignWorker
                    setValue={form.setValue}
                    nationality={userData?.nationality}
                    countriesData={countriesData}
                    checkboxes={
                      <>
                        {renderCheckboxField("foreignCompanyCheckbox1", t("consultation:foreignCompanyCheckbox1"))}
                        {renderCheckboxField("foreignCompanyCheckbox2", t("consultation:foreignCompanyCheckbox2"))}
                      </>
                    }
                  />
                }
                {
                  !isStudent && !isForeignWorker && (
                    <>
                      <Box>
                        <EmployerFields
                          limit={EMPLOYER_FIELDS_LIMIT}
                        />
                      </Box>
                      <Box
                        display="flex"
                        mb={3}
                        mt={isSmUp ? 0 : 2}
                      >
                        {renderCheckboxField("nipConfirmation", t(isUniformedServices ? "consultation:uniformServiceConfirmation" : "consultation:nipConfirmation"))}
                      </Box>
                    </>
                  )
                }
                <Box mb={1}>
                  <Typography variant="body1">
                    {t("consultation:sickLeaveAddress")}
                  </Typography>
                </Box>
                <SickLeavePatientAddress
                  fieldsNamePrefix={""}
                  nationality={userData?.nationality}
                  addressFieldsDisabled={addressFieldsDisabled}
                  countriesData={countriesData}
                  setValue={form.setValue}
                />
                <Box
                  display="flex"
                  justifyContent="flex-end"
                >
                  {
                    addressFieldsDisabled ? (
                      <ButtonTextUnderlined
                        color="primary"
                        onClick={() => setAddressFieldsDisabled(false)}
                      >
                        <Typography variant="body1">
                          {t("defaultTranslations:edit")}
                        </Typography>
                      </ButtonTextUnderlined>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        type="button"
                        disabled={isPersonalDataSaving || isSaving}
                        onClick={() => handleSavePersonalData(
                          setAddressFieldsDisabled,
                          setIsPersonalDataSaving,
                          form.getValues(["street", "houseNumber", "apartmentNumber", "postalCode", "city"]),
                          userId
                        )}
                      >
                        {t("defaultTranslations:save")}
                      </Button>
                    )
                  }
                </Box>
                <Box mt={3}>
                  <Typography variant="body1">
                    {t("consultation:sickLeaveAddressOfStay")}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  mt={1}
                  mb={2.5}
                >
                  <Checkbox
                    id="otherAddress"
                    checked={isOtherAddressCheckboxChecked}
                    className={classes.checkboxOtherAddress}
                    checkedIcon={<CheckBoxIcon color="primary"/>}
                    icon={<CheckBoxOutlineBlankIcon />}
                    onChange={() => handleOnChangeOtherAddressChange()}
                  />
                  <Box mt={1.5}>
                    <Typography variant="body2">
                      <label htmlFor="otherAddress">
                        {t("consultation:sickLeaveSameAddress")}
                      </label>
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  {
                    !isOtherAddressCheckboxChecked && (
                      <SickLeavePatientAddress
                        addressFieldsDisabled={false}
                        otherAddressEdit={true}
                        setValue={form.setValue}
                        countriesData={countriesData}
                        fieldsNamePrefix={""}
                      />
                    )
                  }
                </Box>
                <Box>
                  <ExtraQuestions userData={userData} />
                </Box>
              </Box>
              <Box
                mt={4}
              >
                <Typography
                  variant="body1"
                >
                  {t("prescription:surveyQuestions:fileZoneLabel")}
                </Typography>
                <Box
                  mt={2}
                >
                  <FileDropzone
                    apiErrors={errors}
                    resetApiErrors={() => setErrors([])}
                    handleInputChange={handleFileChange}
                    customClassUploadDropzone={classes.uploadDropzone}
                  />
                </Box>
              </Box>
              {
                isStudent && (
                  <Box
                    display="flex"
                    mb={3}
                    mt={isSmUp ? 0 : 2}
                  >
                    {renderCheckboxField("studentConfirmation", t("consultation:studentConfirmation"))}
                  </Box>
                )
              }
              <Box
                display="flex"
                justifyContent="flex-end"
                mt={4}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isLoading || !addressFieldsDisabled}
                  onClick={handleGoToNextStep}
                >
                  {t("consultation:nextButton")}
                </Button>
              </Box>
            </Box>
          </Box>
          {isSummaryStep && (
            <SickLeaveSurveySummary
              handleGoToNextStep={handleGoToNextStep}
              formData={surveyValues}
              orderedSickLeave={orderedSickLeave}
              setSurveyStatus={setSurveyStatus}
              isLoading={isLoading}
              renderCheckboxField={renderCheckboxField}
              startDate={newStartDate}
              endDate={newEndDate}
            />
          )}
        </FormProvider>
      </Box>
    </Box>
  )
}

export default SickLeaveSurvey
