import React, {FC, RefObject, useEffect, useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {useLocation} from "react-router"
import {Box, Grid } from "@material-ui/core"
import { push } from "connected-react-router"
import i18n from "i18next"

import {useAppDispatch, useAppSelector} from "../../hooks/storeHooks"
import useGoToConsultationPage from "../../hooks/useGoToConsultationPage"
import useIsB2BClinic from "../../hooks/useIsB2BClinic"
import { useUserIsUnderage } from "../../hooks/useUserIsUnderage"
import store from "../../store"
import {selectClinicSettings} from "../../store/clinic/clinicSettings.selectors"
import { selectComingConsultations, selectLatestConsultations } from "../../store/consultations/consultations.selectors"
import { selectComingExamsItems } from "../../store/exams/exams.selectors"
import {selectUser} from "../../store/user/user.selectors"
import LoaderBox from "../../components/common/loaderBox/LoaderBox.component"
import FillUserDataInformationPanel
  from "../../components/informationPanel/fillUserDataInformationPanel/FillUserDataInformationPanel.component"
import UserDataCard from "../../components/user/common/UserDataCard.component"
import UserFinancesTitleNode
  from "../../components/user/userFinances/userFinancesTitleNode/UserFinancesTitleNode.component"
import UserFreeNfzConsultations from "../../components/user/userFinances/userNfzConsultations/UserNfzConsultations.component"
import UserVouchers from "../../components/user/userFinances/UserVouchers.component"
import UserAddressData from "../../components/user/userProfile/userAddressData/UserAddressData.component"
import UserContactData from "../../components/user/userProfile/userContactData/UserContactData.component"
import UserParentAddressData from "../../components/user/userProfile/userParentAddressData/UserParentAddressData.component"
import UserParentPersonalData from "../../components/user/userProfile/userParentPersonalData/UserParentPersonalData.component"
import UserPersonalData from "../../components/user/userProfile/userPersonalData/UserPersonalData.component"
import UserRegulations from "../../components/user/userProfile/userRegulations/UserRegulations.component"
import UserSecuritySettings from "../../components/user/userProfile/UserSecuritySettings.component"
import AddNewVoucherModal from "../../components/voucher/addNewVoucherModal/AddNewVoucherModal.component"
import { canBuyVoucherCode } from "../../components/voucher/voucherCodeToBuy/VoucherCodeToBuy.utils"
import { checkFeatureAvailability } from "../../store/clinic/clinicSettings.utils"
import { getIntegrationType } from "../../utils/getIntegrationType"
import { scrollToRefElement } from "../../utils/scrollTo"
import { RoutePath } from "../../routes/Routes.types"
import { useUserProfilePageStyles } from "./UserProfilePage.styles"

const UserProfilePage: FC = () => {
  const {t} = useTranslation()
  const classes = useUserProfilePageStyles()
  const { B2BClinic } = useIsB2BClinic()
  const clinicSettings = useSelector(selectClinicSettings)
  const vPOZB2b = clinicSettings?.pozformB2BActive
  const benefitSystemIntegrationEnabled = checkFeatureAvailability().enableBenefitSystemIntegration // todo change to general checkbox (hide finances in menu and finances section)
  const showPhoneNumberOnCompleteDataView = checkFeatureAvailability().showPhoneNumberOnCompleteDataView
  const {hash} = useLocation()
  const myProfile = useRef<HTMLDivElement | null>(null)
  const finances = useRef<HTMLDivElement | null>(null)
  const refs: Record<string, RefObject<HTMLDivElement>|null> = { myProfile, finances }
  const [fillUserDataInformationPanel, setFillUserDataInformationPanel] = useState(false)
  const comingConsultations = useAppSelector(selectComingConsultations)
  const latestConsultations = useAppSelector(selectLatestConsultations)
  const exams = useAppSelector(selectComingExamsItems)
  const voucherCodeToBuy = checkFeatureAvailability().voucherCodeToBuy
  const canBuyVoucher = canBuyVoucherCode()
  const dispatch = useAppDispatch()
  const {
    clinicSignalIdunaSettings: { insuranceVerificationEnabled },
    frontendSettings: { modules },
    clinicAllianzSettings: { ewkaVerificationEnabled, onyxVerificationEnabled }
  } = useAppSelector(selectClinicSettings)
  const integrationType = getIntegrationType(
    insuranceVerificationEnabled,
    modules.includes("s7health"),
    ewkaVerificationEnabled,
    onyxVerificationEnabled
  )

  const user = useAppSelector(selectUser)

  const underageUserWithRegisteredParent = !!useUserIsUnderage() && !!user.user_parent
  const { goToMakeConsultationPage } = useGoToConsultationPage()

  useEffect(() => {
    if (hash) {
      const urlHash = hash.replace(/^#/, "")
      scrollToRefElement(refs[urlHash])
    }
  }, [hash])

  useEffect(() => {
    return () => sessionStorage.removeItem("checkPersonalData")
  }, [])

  useEffect(() => {
    if (user && showPhoneNumberOnCompleteDataView && sessionStorage.getItem("checkPersonalData")) {
      if (user.hasCompletePersonalData) {
        if (voucherCodeToBuy && canBuyVoucher && !comingConsultations.length && !latestConsultations.length && !exams.length) {
          dispatch(push(`/${i18n.language}${RoutePath.VOUCHERS_BUY}?withoutRedirect=1`))

          return
        }

        goToMakeConsultationPage()
      } else {
        setFillUserDataInformationPanel(true)
      }
    }
  }, [user])

  if ((!user || (showPhoneNumberOnCompleteDataView && !fillUserDataInformationPanel && localStorage.getItem("checkPersonalData")))) {
    return <LoaderBox/>
  }

  if (fillUserDataInformationPanel) {
    return <FillUserDataInformationPanel showPhoneNumber={true} />
  }

  const patientEditAllowed = store.getState()?.clinic?.clinicSettings?.patientEditAllowed ?? true

  return (
    <>
      <UserDataCard
        ref={myProfile}
        title={t("user:myProfile")}
      >
        <Grid container>
          <Grid item xs={12} lg={4} className={classes.gridItem}>
            <Box className={classes.gridItemContentBox}>
              <UserPersonalData
                user={user}
                userParentData={user.user_parent}
                underageUserWithRegisteredParent={underageUserWithRegisteredParent}
                patientEditAllowed={patientEditAllowed}
                integrationType={integrationType}
              />
              {underageUserWithRegisteredParent && <UserParentPersonalData userParentData={user.user_parent} patientEditAllowed={patientEditAllowed}/>}
            </Box>
          </Grid>

          <Grid item xs={12} lg={4} className={classes.gridItem}>
            <Box
              display="flex"
              flexDirection="column"
              className={classes.gridItemContentBox}
            >
              <UserAddressData
                user={user}
                underageUserWithRegisteredParent={underageUserWithRegisteredParent}
                patientEditAllowed={patientEditAllowed}
              />
              {underageUserWithRegisteredParent && <UserParentAddressData userParentData={user.user_parent}/>}
              <UserContactData user={user} patientEditAllowed={patientEditAllowed}/>
            </Box>
          </Grid>

          <Grid item xs={12} lg={4} className={classes.gridItem}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
              className={classes.gridItemContentBox}
            >
              <UserSecuritySettings
                user={user}
                hideChangePassword={onyxVerificationEnabled || ewkaVerificationEnabled}
                integrationType={integrationType}
              />
              <UserRegulations />
            </Box>
          </Grid>
        </Grid>
      </UserDataCard>

      {!benefitSystemIntegrationEnabled && (
        <UserDataCard
          ref={finances}
          titleNode={<UserFinancesTitleNode />}
        >
          <Grid container>
            {
              (!B2BClinic || vPOZB2b) && (
                <Grid item xs={12} lg={4} className={classes.gridItem}>
                  <Box className={classes.gridItemContentBox}>
                    <UserFreeNfzConsultations />
                  </Box>
                </Grid>
              )
            }

            <UserVouchers/>
            <AddNewVoucherModal/>
          </Grid>
        </UserDataCard>
      )}
    </>
  )
}
export default UserProfilePage
