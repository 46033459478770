import React, {FC} from "react"
import {useWatch} from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import CheckboxController from "../../../../../../components/CheckboxController/CheckboxController.component"
import SelectController from "../../../../../../components/commonFormItems/SelectController/SelectController.component"
import TextFieldController
  from "../../../../../../components/commonFormItems/textFieldController/TextFieldController.component"
import EmployerFields from "../../../../../../components/SickLeaveSurvey/EmployerFields.component"
import FormSection from "../FormSection/FormSection.component"
import ForeignCompanyAddress from "./ForeignCompanyAddress/ForeignCompanyAddress.component"
import SickLeaveAddress from "./SickLeaveAddress/SickLeaveAddresses.component"
import SickLeaveDatesController from "./SickLeaveDatesController/SickLeaveDatesController.component"
import {EmploymentType, PatientsRequirementsStepData} from "../../../MedicalQuestionnaireStepperForm.types"

import {useTranslateOptions} from "./SickLeaveSection.hooks"

const SickLeaveSection: FC = () => {
  const { t } = useTranslation()
  const { employmentTypeOptions } = useTranslateOptions()
  const employmentType = useWatch<PatientsRequirementsStepData, "sickLeave.employmentType">({
    name: "sickLeave.employmentType",
  })

  return (
    <FormSection
      title={
        <Typography
          variant='h3'
        >
          <Trans
            i18nKey="medicalQuestionnaire:sickLeaveSectionTitle"
          >
            1 <Typography variant='inherit' component='span' color='primary'>zwolnienie</Typography>?
          </Trans>
        </Typography>
      }
    >
      <Box>
        <SickLeaveDatesController/>
        <Box
          mb={2}
        >
          <SelectController
            name='sickLeave.employmentType'
            label={t("medicalQuestionnaire:employmentTypeLabel")}
            options={employmentTypeOptions}
            required={true}
          />
        </Box>
        {
          employmentType === EmploymentType.UNIFORMED_SERVICES && (
            <Box
              mb={2}
            >
              <TextFieldController
                name='sickLeave.uniformedServiceType'
                label={t("consultation:uniformedServiceType")}
              />
            </Box>
          )
        }
        {
          employmentType === EmploymentType.FOREIGN_COMPANY && (
            <ForeignCompanyAddress/>
          )
        }
        {
          (
            employmentType === EmploymentType.CIVILIAN_PERSONNEL ||
            employmentType === EmploymentType.UNIFORMED_SERVICES
          ) && (
            <Box
              mb={2}
            >
              <Box
                mb={2}
              >
                <EmployerFields
                  limit={2}
                  name='sickLeave.nip'
                />
              </Box>
              <CheckboxController
                name='nipConfirmation'
                label={t("consultation:nipConfirmation")}
                required={true}
              />
            </Box>
          )
        }
        <SickLeaveAddress/>
        {
          employmentType === EmploymentType.STUDENT && (
            <CheckboxController
              name='sickLeave.isStudentConfirmation'
              label={t("consultation:studentConfirmation")}
              required={true}
            />
          )
        }
      </Box>
    </FormSection>
  )
}

export default SickLeaveSection
