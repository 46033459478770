import React, { FC } from "react"
import { Avatar } from "@material-ui/core"
import { AvatarProps } from "@material-ui/core/Avatar/Avatar"

import { useAppSelector } from "../../../hooks/storeHooks"
import { selectClinicSettings } from "../../../store/clinic/clinicSettings.selectors"
import { useAvatarStyles } from "./ImageAvatar.styles"

export interface ImageAvatarProps extends AvatarProps {}

const ImageAvatar: FC<ImageAvatarProps> = (
  {
    src,
    alt,
    classes,
    className,
  }
) => {
  const {
    clinicAllianzSettings: {
      ewkaVerificationEnabled,
      onyxVerificationEnabled
    }
  } = useAppSelector(selectClinicSettings)

  const defaultClasses = useAvatarStyles({
    borderWidth: (ewkaVerificationEnabled || onyxVerificationEnabled) ? 1 : 6
  })

  return (
    <Avatar
      src={src}
      alt={alt || ""}
      classes={{
        root: defaultClasses.root,
        ...classes,
      }}
      className={className}
      imgProps={{
        loading: "lazy"
      }}
    />
  )
}

export default ImageAvatar
