import {Satisfaction} from "../consultationChat/consultationRatingModal/ConsultationRatingModal.types"
import {
  DoctorAdditionalData,
  DoctorSpecializationModel,
  DoctorSpecializationWithCurrencyModel
} from "../doctor/Doctor.types"

export interface StationaryVisitAddress {
  apartmentNumber?: string;
  city?: string;
  houseNumber?: string;
  street?: string;
  facility?: string;
  clinicName?: string;
}

export interface ConsultationModel { // GET /api/v2/consultations/{consultationId}
  id: string; // "44d224b2-dc0d-49d6-9c61-6b2f3f412310"
  created_at: string; // "2021-02-22T15:06:38+01:00"
  modified_at: string; // "2021-06-23T10:36:16+02:00"
  status: string; // (traslated status_number label) "consultation.status.canceled_sms_late", "Anulowana (po czasie)"
  // DO NOT USE "status" as enum for complex computations - use status_number instead
  status_number: ConsultationStatusNumber;// 4;
  consultationType: ConsultationType; //3
  type: string; // "consultation.type.chat"
  rate: Satisfaction | null; // 5
  doctorName: string; // "Beata Gruca",
  doctorId: string; // "f2d9eb96-aacf-4540-a907-e14f50f87791"
  doctorNameWithDegree: string; // "lek. Beata Gruca"
  patientId: string; // "cb2f2530-6bd2-482b-afcb-da4fa61c3306"
  specialization: DoctorSpecializationWithCurrencyModel;
  chatSession: string; // standard chatSession "2_MX40NTgwNjEwMn5-MTYxNDAwMjc5ODY3OX5JNy9ONThZUU5oTUx3eVJqcXpqbzM2QVJ-fg"
  chatSession2: string; // chatSession for safari browser "1_MX40NjI4NzQ5Mn5-MTYxNDAwMjc5OTA3MH5YYUUrTzFhTWZLOTFaN0RiQmw5K1V3Z21-fg"
  openTokToken: string; // standard openTokToken "T1==cGFydG5lcl9pZD00NTgwNjEwMiZzaWc9ZGMyMDE4YmQ1NzMzZDdiMzhkMGVlYTFmZTJkZDM1ZDE1MDg0MDhiOTpzZXNzaW9uX2lkPTJfTVg0ME5UZ3dOakV3TW41LU1UWXpNVFV6T1RjeU1qVTJNWDVUYjJSSFFYRXpWbmgzTWxKaGJFZEZURkpoUzNCdFIyTi1mZyZjcmVhdGVfdGltZT0xNjMxNTQ4MTAzJnJvbGU9cHVibGlzaGVyJm5vbmNlPTE2MzE1NDgxMDMuOTE5MjYzNjUzNzU2OSZleHBpcmVfdGltZT0xNjMyMTUyOTAzJmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9"
  openTokToken2: string; // openTokToken for safari browser "T1==cGFydG5lcl9pZD00NTgwNjEwMiZzaWc9ZGMyMDE4YmQ1NzMzZDdiMzhkMGVlYTFmZTJkZDM1ZDE1MDg0MDhiOTpzZXNzaW9uX2lkPTJfTVg0ME5UZ3dOakV3TW41LU1UWXpNVFV6T1RjeU1qVTJNWDVUYjJSSFFYRXpWbmgzTWxKaGJFZEZURkpoUzNCdFIyTi1mZyZjcmVhdGVfdGltZT0xNjMxNTQ4MTAzJnJvbGU9cHVibGlzaGVyJm5vbmNlPTE2MzE1NDgxMDMuOTE5MjYzNjUzNzU2OSZleHBpcmVfdGltZT0xNjMyMTUyOTAzJmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9"
  cost: string; // "0"
  doctor_salary_price: string; // "133"
  doctor_salary_paid_by_hour: null; // ??
  doctor_salary_paid_by_hour_is_first: boolean; // false
  is_payed: boolean; // true
  paymentStatus: ConsultationPaymentStatus | null;
  paymentLink?: URL["href"]; //"https://telemedico.localhost/en/payment/check/52a6a90a-d7da-11eb-8136-0242ac120007/eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE1Nzg1Nzc5MzEsInVzZXJuYW1lIjoidGVsZW1lZGljb19hZG1pbiIsInJvbGVzIjoiUk9MRV9TVVBFUl9BRE1JTixST0xFX0FDVElWRSIsImV4cCI6MTYxMDExMzkzMX0.eYv9CUXbLD1uhbRUV5rGjZZf5PrPsDjunshneq674dOM_u5XXDcpzzcDUzRwpZjuaBgugkvc3cHsajhOX2-3qEwCaQeeyjcfUtUJSTAhs_aEeqjskDAjVtyQBnkBy2IkCur2Uadz5hR4T4CvcDtJMCFgbj82OVVcAsGjM3DGHRTZln2iilkSSrWdVCSZs1UB7-H5lvRznI0mDJlW6ni61FEjm1fazKhMf0NOnssWLCW-zOHABTtrqYjJka9FQUMIXa2toNiMIZUR5tT6bdfm2UI8HLXQhDp111wrw3Tcjy3E6eYLZai0RftU6rZ0hLNFcBpwdro4GFykK1DWBsWbcXSH5Ch3jV3uvueQcwvt6x3mZKDGEvk4Q_tbsa5Rul0_DcemNGHZlkezsi4YxZUMWSPXvfEslBrMQyY4V-TqCf0utWIx4YHYGZu9tq_x2ywPR4V7a2kQzxXL7srXRDAG90t9QErk3fSJBSRXFTMX9CdME5qNG9TkUAJPtfiyszivVhhlvc1I7Uhqc2rumcI3zUSgo4_JsFCSi-r8v34s_MQCzs4gl6LWsr_2-aQ-LjgTYkv71FN8VPMU1dgQGnfmPC3_xIbIKdMQGOGaI6CTBo28F2XCk1IbMTCPBdY81G0_NErAI_lWqoxo2uox_x5FXEKKefalDMlFb73IxGMI0Vw"
  visit_at: string; // "2021-02-22T17:15:00+01:00"
  started_at: string | null;
  finished_at: string | null; //"2021-09-07T10:57:42+02:00"
  language: string; // "pl"
  currency: string; // "PLN"
  minichatDismiss: number; // 1
  video_tested_at: null // ??
  is_interested_in_poz: boolean; // true
  telemedico_poz: null; // ??
  covid_case: null // ??
  medicines?: string[]; // ["05909990021918"]
  manual_consultation: boolean; // false
  first_patient_visit: boolean; //false
  first_visit_modal_opened: boolean; // false
  doctor: DoctorAdditionalData;
  subscription_code: string | null;
  stationaryVisitAddress?: StationaryVisitAddress;
  video_server_type: string; // twilio_video
  paymentGateway: string | null;
  orderedSickLeave?: {
    employment_type: number;
  }
  currencyTable?: {
    current: {
      [key: string]: string|number;
    }
    moneyBox: {
      [key: string]: string|number;
    }
    old: {
      [key: string]: string|number;
    }
  }
  is_interested_in_telemedi_go?: boolean;
  prescriptionQuestionnaireFilled?: boolean;
  sickLeaveQuestionnaireFilled?: boolean;
  multiPrescriptionPatientPopupAnswer: boolean|null;
  isMultiPrescriptionMedicine: boolean|null;
  canCancelStationaryVisit?: boolean;
}

export interface OrderedSickLeaveDetails {
  start_at: string;
  end_at: string;
  employment_type: number;
}

export interface ConsultationListItemModel { // GET /api/v2/consultations
  id: string;// "f260caa3-5e76-4445-8042-0d0abd3044c6";
  created_at: string;// "2020-06-26T12:48:00+02:00";
  status: string; // (traslated status_number label) "consultation.status.canceled_sms_late", "Anulowana (po czasie)"
  // DO NOT USE "status" as enum for complex computations - use status_number instead
  status_number: ConsultationStatusNumber;// 4;
  type: string;// "consultation.type.video";
  doctorName: string;// "Beata Gruca";
  doctorId: string;// "f2d9eb96-aacf-4540-a907-e14f50f87791";
  doctorNameWithDegree: string;// "Dr. Beata Gruca";
  patientName: string // "Pacjent Receptowy"
  patientId: string;// "bd1911ef-870b-4338-bb53-9e29a589ee8a";
  specialization: DoctorSpecializationModel;
  chatSession: string;// "1_MX40NTgwNjEwMn5-MTU1MTk1NTE0NTYxOX55YUlUSXRESG90WjBWU0RWcDByT0JFbmx-fg";
  chatSession2: string;// "1_MX40NTgwNjEwMn5-MTU1MTk1NTE0NTYxOX55YUlUSXRESG90WjBWU0RWcDByT0JFbmx-fg";
  cost: string;// "0";
  doctor_salary_price: string;// "0";
  doctor_salary_paid_by_hour: null; //??
  doctor_salary_paid_by_hour_is_first: boolean; //false
  is_payed: boolean;// true;
  visit_at: string;// "2020-06-26T13:48:00+02:00";
  started_at: string | null;// null;
  finished_at: string | null;// null;
  language: string;// "pl";
  currency: string;// "PLN";
  minichatDismiss: number;// 1;
  video_tested_at: string | null;// null
  is_interested_in_poz: boolean; //false
  telemedico_poz: null; //??
  covid_case: null //??
  manual_consultation: boolean; // false
  first_patient_visit: boolean; //false
  first_visit_modal_opened: boolean; //false
  paymentStatus: ConsultationPaymentStatus | null; //"99"
  paymentLink?: URL["href"];
  isPoz: boolean; //true
  pozform: string; //"a7209294-13f2-46e9-8d4a-ad95a6adf6e2"
  pozform_status: string; //"ACTIVE", //TODO: make enum?
  account_verified_by_selfie: boolean; //false
  directLink: URL["href"]; //"http://staging.tmdi04.com/api/goto/consultation-patient/4104d8ff-5a96-40d5-89b0-f56969ee82f1/5c347254-63f0-4cc8-b07d-6b3f4d33ec7d/eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE1NDA5ODAzMjMsImV4cCI6MTU3MjUxNjMyMywidXNlcm5hbWUiOjI1MzY5MDUsInJvbGVzIjoiUk9MRV9QQVRJRU5ULFJPTEVfQUNUSVZFLFJPTEVfUkVHSVNURVIifQ.InTOgdB0_3kNxZ4we8yxV-XlaVQkh4_QwdciRtd2kRPXDEn8skYOsM_2xh2gI81rFEyU7cKKIIa3JlfX3-y50Y82Nqpju97KGJfs2UA6KsXBWXtuhAasKtxIokyKnWrGrMZujYkaKg1NsV5-fsMFC24SzQkYerpPPjLxTYCvi2HOyMw2yICnYVifwMKnVXfp0sj24uNDWeq9AaDSP2Wquq_GIN-tiGrKAB5Hh30KLcJNP3JDbgqiFgRlVRGXgup-eJkw0zp1_OaJ1SvafcmqQ8QKtThFCL3MMpqXdtOvimh0xhJM1mG4QWlV3fcfhOYRhyfZ14lUdX0RLMYSHppBRr-xfd9GVSxfAT3J2CZ1AKFMk_4IyOHVJDCO8mkVnIQtUm-zLnN4DRrh52LqMCFtjYeg12AKviR0FVgIl0tKZE7nRF2u7vvA5ZDeIncL3g4kcBbKD5ePVHJJOpqPzWdlSRCF1cpxiqGPHrLFsKNxLqiTMXKTqc0ilZSvT9YEGJB69naKq3jdJ-LngJ5RU7EKhubjUImtxyRyG7HMh-6KQGz4ns8ZrmB-li0HX3rUdzdA_GI7_hG0tYKh0HDBvDx7X3rcV_2lBllKWheXuhzc4HFS4Qi84ry7cDa3VKxFL9akaZfQjYC48byt6Xy7vnT13nImCwWxRAi-x_x-6ACqKdM?_locale=pl&hideToolbars=1"
  doctorDegree: string;
  doctor_avatar: URL["pathname"] | null; //"/assets/avatars/f2d9eb96-aacf-4540-a907-e14f50f87791.png": ;
  doctor_rating: number | null; // 3.25
  doctorRtptpn: string; //"5013201"
  openTokToken: string; // "T1==cGFydG5lcl9pZD00NTgwNjEwMiZzaWc9OWJhYThjYzlkOWUyMDAzNmJkNWY0ZDc1ZDhjMjY4MTY1Yjk2Y2UwNTpzZXNzaW9uX2lkPTFfTVg0ME5UZ3dOakV3TW41LU1UWXlPVGszTURNNE5UWXpOWDU2Y1U0M1JGQlRORmR6V0c1TFZUVkpRaXRRTWtJd1UzRi1mZyZjcmVhdGVfdGltZT0xNjI5OTcwNzM0JnJvbGU9cHVibGlzaGVyJm5vbmNlPTE2Mjk5NzA3MzQuMDMyNTEzMjE0NjU2NDgmZXhwaXJlX3RpbWU9MTYzMDU3NTUzNCZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ=="
  openTokToken2: string; // "T1==cGFydG5lcl9pZD00NjI4NzQ5MiZzaWc9NGVmNWRmMjMyY2U4NGM5MzBjNGFiNmQ5NjBmMGU2OWQ3YzIwOGJmMDpzZXNzaW9uX2lkPTJfTVg0ME5qSTROelE1TW41LU1UWXlPVGszTURNNE5qRXhOMzV3VDJ0a1lrWmpPVXByUXpSYWNsVXdObEpPY0RkWFJXMS1mZyZjcmVhdGVfdGltZT0xNjI5OTcwNzM0JnJvbGU9cHVibGlzaGVyJm5vbmNlPTE2Mjk5NzA3MzQuMDMyNjYxNjc1ODE2MyZleHBpcmVfdGltZT0xNjMwNTc1NTM0JmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9"
  rate: null; //??
  consultationType: ConsultationType; //3
  modified_at?: string;// "2020-06-26T12:48:09+02:00";
  facilityName?: string; // ASSUMPTION
  facilityAddress?: string; // ASSUMPTION
  doctor: DoctorAdditionalData;
  stationaryVisitAddress?: StationaryVisitAddress;
  drugNames?: string[]; // ["Escapelle tabletka 1500 mikrogramów 1500 mcg 1 tabl. "]
  orderedSickLeave?: OrderedSickLeaveDetails;
  healthPlannerSurveyFilled?: boolean;
  prescriptionQuestionnaireFilled?: boolean;
  sickLeaveQuestionnaireFilled?: boolean;
  is_interested_in_telemedi_go?: boolean;
  isMedicalSurveyFilled?: boolean;
  isPatientMedicalSurveyRequired?: boolean;
  canCancelStationaryVisit?: boolean;
}

export enum ConsultationType {
  "VIDEO" = 1,
  "PHONE" = 2,
  "CHAT" = 3,
  "QUESTION" = 4,
  "SAAS" = 5,
  "ON_SITE" = 6,
  "PRESCRIPTION_ONLY" = 8,
  "SICK_LEAVE" = 9,
  "TYPE_STATIONARY_NON_INTEGRATED" = 10
}

// consultations statuses docs: https://telemedico.atlassian.net/l/c/5Upeo7r2
export enum ConsultationStatusNumber {
  "CANCELED_AFTER_CHECK_IN" = -4,
  "CANCELED_BY_PATIENT_SMS_LATE" = -3,
  "CANCELED_BY_PATIENT_SMS" = -2,
  "CANCELED_AFTER_TIME" = -1,
  "CANCELED" = 0,
  "CREATED" = 1,
  "RESERVED" = 2,
  "CONFIRMED" = 3,
  "STARTED" = 4,
  "POSTPONED" = 5,
  "POSTPONED_AGAIN" = 6,
  "FINISHED" = 100,
  "FINISHED_DUPLICATED" = 101,
  "FINISHED_NO_ANSWER" = 102,
  "FINISHED_NO_ANSWER_OTHER" = 103,
  "FINISHED_VOICE_MAIL" = 104,
  "FINISHED_INCORRECT_PATIENT_DATA" = 105,
  "FINISHED_PICKED_UP_ANOTHER_PERSON" = 106,
  "FINISHED_TECHNICAL_ERROR" = 107,
  "MANUAL_CONSULTATION" = 108,
}

// STATUS NUMBERS
export const COMING_CONSULTATION_STATUS_NUMBERS = [
  ConsultationStatusNumber.CREATED,
  ConsultationStatusNumber.RESERVED,
  ConsultationStatusNumber.CONFIRMED,
  ConsultationStatusNumber.POSTPONED,
  ConsultationStatusNumber.POSTPONED_AGAIN,
]

export const CANCELED_CONSULTATION_STATUS_NUMBERS = [
  ConsultationStatusNumber.CANCELED_AFTER_CHECK_IN,
  ConsultationStatusNumber.CANCELED_BY_PATIENT_SMS_LATE,
  ConsultationStatusNumber.CANCELED_BY_PATIENT_SMS,
  ConsultationStatusNumber.CANCELED_AFTER_TIME,
  ConsultationStatusNumber.CANCELED,
]

export const FINISHED_WITH_ERROR_CONSULTATION_STATUS_NUMBERS = [
  ConsultationStatusNumber.FINISHED_DUPLICATED,
  ConsultationStatusNumber.FINISHED_NO_ANSWER,
  ConsultationStatusNumber.FINISHED_NO_ANSWER_OTHER,
  ConsultationStatusNumber.FINISHED_VOICE_MAIL,
  ConsultationStatusNumber.FINISHED_INCORRECT_PATIENT_DATA,
  ConsultationStatusNumber.FINISHED_PICKED_UP_ANOTHER_PERSON,
  ConsultationStatusNumber.FINISHED_TECHNICAL_ERROR,
]

export const FINISHED_WITH_SUCCESS_CONSULTATION_STATUS_NUMBERS = [
  ConsultationStatusNumber.FINISHED,
]

export const FINISHED_WITH_NO_SUCCESS_CONSULTATION_STATUS_NUMBERS = [
  ...CANCELED_CONSULTATION_STATUS_NUMBERS,
  ...FINISHED_WITH_ERROR_CONSULTATION_STATUS_NUMBERS
]

export enum ConsultationPaymentStatus {
  NEW = "1",
  PENDING = "2",
  WAITNG_FOR_CONFIRMATION = "3",
  CANCELED = "4",
  REJECTED = "5",
  REFUNDED = "6",
  COMPLETED = "99",
}

export interface GetConsultationsListParams {
  page?: number;
  limit?: number;
  status?: ConsultationListParamsStatus;
  statusNumber?: ConsultationStatusNumber[];
  sort?: ConsultationListParamsSort;
  startVisitAt?: string; //2021-06-01T10:00:00
  endVisitAt?: string; //2021-06-02T10:00:00
  lang?: string | null; //default: "pl"
}

export enum ConsultationListParamsStatus {
  PLANNED = "planned",
  ARCHIVED = "archived",
}

export enum ConsultationStripePaymentGateway {
  STRIPE = "stripe",
}

export enum ConsultationListParamsSort {
  ASC = "ASC",
  DESC = "DESC", // default value
}

export interface ConsultationListResponse {
  items: ConsultationListItemModel[];
  total: number;
}

export interface LabelForConsultationLanguageParam {
  [key: string]: string|Date
}

export type ConsultationTermsListResponse = {
  additional_info: {
    [key: string]: string;
  },
  count: number;
  items: ConsultationTermsListItems[];
  limit: number;
  total: number;
  _links: {
    first: string;
    last: string;
    next: string;
    self: string;
  };
}

export type ConsultationTermsListItems = {
  channels: number;
  doctors: string[];
  doctorsForSlot: ConsultationTermsListDoctorsForSlot[];
  start: Date;
  end: Date;
}

type ConsultationTermsListDoctorsForSlot = {
  allow_consultation_only_from_specialists: boolean;
  avatar: string | null;
  channels: number[];
  doctor_id: string;
  doctor_name_with_degree: string;
  grade: number;
  instant_chat_enabled: boolean;
  price: string;
  ratings_count: number;
}

export type GetConsultationTermsParams = {
  consultationType: number;
  language: string;
  specializationId: string;
}
export type ConsultationSurveyParams = {
  [key: string]: boolean;
}

export interface GetExamCartDetails {
  is_paid: number;
}
